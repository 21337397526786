import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './header.css';
import Utils from '../../Utils';

const { deleteCookies } = Utils;

const Header = (props) => {
  const {
    isLogged, setIsLogged, profile, hasPassword, firstColor, secondColor, logo,
  } = props;
  const history = useHistory();

  const logout = () => {
    setIsLogged(false);
    deleteCookies();
    history.push('/');
  };
  return (
    <header style={{ backgroundColor: firstColor }}>
      <div>
        <Link style={{ color: secondColor }} to="/">
          <img
            className="Logo"
            src={logo}
            alt="logo"
          />
        </Link>
      </div>
      <div className="Menu">
        {
            isLogged ? (
              <>
                {
                  profile !== 0
                    ? (
                      <div className="Item">
                        <Link style={{ color: secondColor }} to="/profile">Perfil</Link>
                      </div>
                    ) : (<></>)
                }
                {
                  profile === 3 && hasPassword
                    ? (
                      <div className="Item">
                        <Link style={{ color: secondColor }} to="/client">Ficha</Link>
                      </div>
                    ) : (<></>)
                }
                {
                  profile !== 0 && hasPassword
                    ? (
                      <div className="Item">
                        <Link style={{ color: secondColor }} to="/search">Documentos</Link>
                      </div>
                    ) : (<></>)
                }
                {
                  profile === 0 || profile === 1
                    ? (
                      <div className="Item">
                        <Link style={{ color: secondColor }} to="/companies">Compañías</Link>
                      </div>
                    ) : (<></>)
                }
                {
                  profile === 0
                    ? (
                      <div className="Item">
                        <Link style={{ color: secondColor }} to="/clients">Clientes</Link>
                      </div>
                    ) : (<></>)
                }
                {
                  (profile === 1) && hasPassword
                    ? (
                      <div className="Item">
                        <Link style={{ color: secondColor }} to="/users">Usuarios</Link>
                      </div>
                    ) : (<></>)
                }
                <div className="Empty" />
                <div className="Item Logout" onClick={logout}>
                  <Link style={{ color: secondColor }} to="/logout">Desconectarse</Link>
                </div>
              </>
            ) : (
              <div className="Item">
                <Link style={{ color: secondColor }} to="/login">Acceder</Link>
              </div>
            )
          }
      </div>
    </header>
  );
};


Header.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  setIsLogged: PropTypes.func.isRequired,
  profile: PropTypes.number.isRequired,
  hasPassword: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired,
  firstColor: PropTypes.string.isRequired,
  secondColor: PropTypes.string.isRequired,
};

export default Header;
