import React, { useRef, useEffect, useState } from 'react';
import './consumedArticles.css';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import DatePicker from '../../../node_modules/antd/lib/date-picker/index';
import Input from '../../../node_modules/antd/lib/input';
import Tooltip from '../../../node_modules/antd/lib/tooltip';
import Button from '../../../node_modules/antd/lib/button';
import Spin from '../../../node_modules/antd/lib/spin';
import Alert from '../../../node_modules/antd/lib/alert';
import Api from '../../Api';
import Utils from '../../Utils';
import notification from '../../../node_modules/antd/lib/notification';
import Pager from '../Pager/Pager';
import ConsumedArticle from './ConsumedArticle';

const { RangePicker } = DatePicker;
const { get } = Api;
const { buildHeaders, getUrl, generateParams } = Utils;

const ConsumedArticles = (props) => {
  const {
    thirdColor, fourthColor, fifthColor, orderLines, setOrderLines,
  } = props;
  const [mouseIsOverSearch, setMouseIsOverSearch] = useState(false);
  const [articles, setArticles] = useState([]);
  const [dates, setDates] = useState([null, null]);
  const [filterValues, setFilterValues] = useState({
    page: 1, elementsPerPage: 10, filter: '', dates: ',',
  });
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    setFilterValues({ ...filterValues, page: 1, dates });
  }, [dates]);

  useEffect(() => {
    (
      async () => {
        setLoading(true);
        const {
          page, elementsPerPage, filter, dates,
        } = filterValues;
        const params = [
          { key: 'pagina', value: page - 1 },
          { key: 'numero', value: elementsPerPage },
          { key: 'filtro', value: filter },
          { key: 'dates', value: dates },
        ];
        const articlesResponse = await get(`getconsumedarticles?${generateParams(params)}`, buildHeaders(), getUrl());
        const {
          error, mensaje, consumedArticles, numberConsumedArticles,
        } = articlesResponse;
        if (error) {
          notification.error({
            message: 'Error!',
            description: mensaje,
          });
        } else {
          setArticles(consumedArticles);
          setTotalItems(numberConsumedArticles);
        }
        setLoading(false);
      }
    )();
  }, [filterValues]);

  const handleDateChange = (e) => {
    if (e) {
      setDates([
        e[0] ? e[0].format('YYYY-MM-DD') : '',
        e[1] ? e[1].format('YYYY-MM-DD') : '',
      ]);
    } else {
      setDates([]);
    }
  };

  const handleSearchButtonClick = () => {
    setFilterValues({
      ...filterValues, dates, filter: filterRef.current.state.value, page: 1,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <div className="ConsumedArticles">
      <div className="Header">
        <RangePicker
          allowEmpty={[true, true]}
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          placeholder={['Fecha inicio', 'Fecha fin']}
        />
        <div className="SearchConsumedArticles">
          <Input
            defaultValue=""
            ref={filterRef}
            id="ConsumedArticlesFilter"
            onKeyDown={handleKeyDown}
          />
          <Tooltip title="Buscar">
            <Button
              onBlur={() => setMouseIsOverSearch(false)}
              onFocus={() => setMouseIsOverSearch(true)}
              onMouseOut={() => setMouseIsOverSearch(false)}
              onMouseOver={() => setMouseIsOverSearch(true)}
              style={{ color: fourthColor, backgroundColor: `${mouseIsOverSearch ? fifthColor : thirdColor}`, borderColor: `${mouseIsOverSearch ? fifthColor : thirdColor}` }}
              type="primary"
              shape="square"
              icon={<SearchOutlined />}
              onClick={handleSearchButtonClick}
            />
          </Tooltip>
        </div>
      </div>
      {
        loading ? (
          <Spin tip="Cargando...">
            <Alert
              message="Cargando"
              description="Estamos obteniendo la información del servidor..."
              type="info"
            />
          </Spin>
        ) : (
          <>
            <div className="Fields">
              <div>ARTÍCULO</div>
              <div>CANTIDAD</div>
              <div>FECHA</div>
              <div />
            </div>
            <div className="Container">
              {
                articles.map((article, i) => (
                  <ConsumedArticle
                    article={article}
                    orderLines={orderLines}
                    setOrderLines={setOrderLines}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    thirdColor={thirdColor}
                    fourthColor={fourthColor}
                    fifthColor={fifthColor}
                  />
                ))
              }
            </div>
          </>
        )
      }
      <Pager
        totalItems={totalItems}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
    </div>
  );
};

ConsumedArticles.propTypes = {
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
  orderLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOrderLines: PropTypes.func.isRequired,
};

export default ConsumedArticles;
