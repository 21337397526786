import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Utils from '../../Utils';

const { formatDecimals } = Utils;

const Charge = (props) => {
  const { charge } = props;
  const {
    ImporteMovCob,
    NumDocMovCob,
    OrdenMovCob,
    ReciboMovCob,
    serfac,
    TotalCabec,
    FechaMovCob,
    FechaCabec,
  } = charge;
  return (
    <>
      <div className="Charge">
        <div>
          <div>{NumDocMovCob}</div>
          <div>
            {`Serie: ${serfac}`}
          </div>
        </div>
        <div>
          <div>{ReciboMovCob}</div>
        </div>
        <div>
          <div>{OrdenMovCob}</div>
        </div>
        <div>
          <div>
            {`Cobro: ${moment(FechaMovCob).format('DD/MM/YYYY')}`}
          </div>
          <div>
            {`Factura: ${moment(FechaCabec).format('DD/MM/YYYY')}`}
          </div>
        </div>
        <div>
          <div>{`${formatDecimals(ImporteMovCob, 2)} €`}</div>
          <div>{`${formatDecimals(TotalCabec, 2)} €`}</div>
        </div>
      </div>
      <hr />
    </>
  );
};

Charge.propTypes = {
  charge: PropTypes.shape({
    ImporteMovCob: PropTypes.number.isRequired,
    NumDocMovCob: PropTypes.number.isRequired,
    OrdenMovCob: PropTypes.number.isRequired,
    ReciboMovCob: PropTypes.number.isRequired,
    serfac: PropTypes.string.isRequired,
    TotalCabec: PropTypes.number.isRequired,
    FechaMovCob: PropTypes.string.isRequired,
    FechaCabec: PropTypes.string.isRequired,
  }).isRequired,
};

export default Charge;
