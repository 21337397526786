import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Contract = (props) => {
  const { contract, openModal } = props;
  const {
    CodTercero,
    CodTipoContrato,
    DescTipoContrato,
    Documento,
    Fecha,
    RPTTipoContrato,
    TipoContrato,
    TipoTercero,
    idemp,
    ImgFirmaContrato,
    EstadoContrato,
  } = contract;

  const statusColor = {
    P: 'rgb(255, 200, 200)',
    R: 'rgb(253, 227, 180)',
    X: '#e0fae7',
  };

  const status = {
    P: 'PENDIENTE DE FIRMA',
    R: 'PENDIENTE DE REVISIÓN',
    X: 'FIRMADO',
  };

  return (
    <div
      className="Contract"
      onClick={() => openModal(Documento, DescTipoContrato, CodTipoContrato, EstadoContrato)}
      style={{ backgroundColor: statusColor[EstadoContrato] ? statusColor[EstadoContrato] : '' }}
    >
      <div className="Head">
        <div>{moment(Fecha).format('DD/MM/YYYY')}</div>
      </div>
      <div className="Body">
        <div className="ContractType">{DescTipoContrato}</div>
        <div className="ContractImage" />
        <div className="Signed">{status[EstadoContrato] || ''}</div>
      </div>
    </div>
  );
};

Contract.propTypes = {
  contract: PropTypes.shape({
    CodTercero: PropTypes.number.isRequired,
    CodTipoContrato: PropTypes.number.isRequired,
    DescTipoContrato: PropTypes.string.isRequired,
    Documento: PropTypes.string.isRequired,
    Fecha: PropTypes.string.isRequired,
    RPTTipoContrato: PropTypes.string.isRequired,
    TipoContrato: PropTypes.number.isRequired,
    TipoTercero: PropTypes.string.isRequired,
    idemp: PropTypes.number.isRequired,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
};

export default Contract;
