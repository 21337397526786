import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../node_modules/antd/lib/button';

const ListUsers = (props) => {
  const {
    users,
    onClickResetPassword,
    onClickDeleteUser,
    onClickManagePermissions,
  } = props;

  return (
    <div className="ListUsers">
      <div>
        <div>
          <div><b>EMPRESA</b></div>
          <div><b>USUARIO</b></div>
          <div><b>GESTIONAR PERMISOS</b></div>
          <div><b>RESTAURAR CONTRASEÑA</b></div>
          <div><b>ELIMINAR USUARIO</b></div>
        </div>
        <hr />
      </div>
      {
        users.map((user) => {
          const { RzsClient: company, UsuarioB2B: username } = user;
          return (
            <div key={username}>
              <div>
                <div>{company}</div>
                <div>{username}</div>
                <div>
                  <Button
                    onClick={() => onClickManagePermissions(username)}
                  >
                    Permisos
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => onClickResetPassword(username)}
                  >
                    Restaurar
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => onClickDeleteUser(username)}
                  >
                    Eliminar
                  </Button>
                </div>
              </div>
              <hr />
            </div>
          );
        })
      }
    </div>
  );
};

ListUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    Codclient: PropTypes.string.isRequired,
    UsuarioB2B: PropTypes.string.isRequired,
    RzsClient: PropTypes.string.isRequired,
    idemp: PropTypes.number.isRequired,
  })).isRequired,
  onClickResetPassword: PropTypes.func.isRequired,
  onClickDeleteUser: PropTypes.func.isRequired,
  onClickManagePermissions: PropTypes.func.isRequired,
};

export default ListUsers;
