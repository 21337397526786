import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

const ModalFooter = (props) => {
  const {
    buttons,
  } = props;
  return (
    <>
      {
      buttons.map((button) => (
        <Button
          key={button.text}
          disabled={button.disabled}
          onClick={() => button.onClick()}
        >
          {button.text}
        </Button>
      ))
    }
    </>
  );
};

ModalFooter.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

export default ModalFooter;
