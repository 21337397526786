import React from 'react';
import PropTypes from 'prop-types';
import Article from './Article';
import './articles.css';

const Articles = (props) => {
  // console.log(props);
  const {
    articles, updateOrderLines, clientInfo, seePrices,
  } = props;
  const {
    IvaArtClient: taxArticClient,
    PORCEIVACLIENT: taxArticClientPercent,
    Tarclient: clientRate,
  } = clientInfo;
  return (
    <div className="Articles">
      {
        articles.map((article) => {
          const {
            CodArtic,
            Descuento,
            Precio,
            descartic,
            escsnartic,
            pvp1artic,
            pvp2artic,
            pvp3artic,
            pvp4artic,
            ClaseIvas: taxClass,
            PorceIvaIvas: taxPercent,
            PorceRECIvas: taxSurchargePercent,
            images,
            combos,
          } = article;

          return (
            <Article
              key={CodArtic}
              CodArtic={CodArtic}
              Descuento={Descuento}
              Precio={Precio}
              descartic={descartic}
              escsnartic={escsnartic}
              pvp1artic={pvp1artic}
              pvp2artic={pvp2artic}
              pvp3artic={pvp3artic}
              pvp4artic={pvp4artic}
              updateOrderLines={updateOrderLines}
              taxClass={taxClass}
              taxPercent={taxPercent}
              taxSurchargePercent={taxSurchargePercent}
              taxArticClient={taxArticClient}
              taxArticClientPercent={taxArticClientPercent}
              clientRate={clientRate}
              images={images}
              combos={combos}
              seePrices={seePrices}
            />
          );
        })
      }
    </div>

  );
};

Articles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    CodArtic: PropTypes.string.isRequired,
    Descuento: PropTypes.number,
    Precio: PropTypes.number,
    descartic: PropTypes.string.isRequired,
    escsnartic: PropTypes.number.isRequired,
    pvp1artic: PropTypes.number.isRequired,
    pvp2artic: PropTypes.number.isRequired,
    pvp3artic: PropTypes.number.isRequired,
    pvp4artic: PropTypes.number.isRequired,
  })).isRequired,
  clientInfo: PropTypes.shape({
    IvaArtClient: PropTypes.string.isRequired,
    PORCEIVACLIENT: PropTypes.number.isRequired,
    Tarclient: PropTypes.string.isRequired,
  }).isRequired,
  updateOrderLines: PropTypes.func.isRequired,
  seePrices: PropTypes.number.isRequired,
};

export default Articles;
