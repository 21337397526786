import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import Spin from '../../../node_modules/antd/lib/spin';
import Alert from '../../../node_modules/antd/lib/alert';
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel';
import Upload from '../../../node_modules/antd/lib/upload';
import TextAreaWithLabel from '../TextAreaWithLabel/TextAreaWithLabel';
import './createavt.css';

const CreateAvt = (props) => {
  const {
    loading,
    clientOptions,
    setAvtClient,
    avtClient,
    profile,
    onPaste,
    modalReason,
    images,
    handlePreview,
    handleChange,
    avtReasonRef,
  } = props;

  return (
    <div className="CreateAvt">
      {
        loading ? (
          <Spin tip="Enviando aviso...">
            <Alert
              message="Espera"
              description="Se está enviando la información al servidor, tomará unos segundos..."
              type="info"
            />
          </Spin>
        ) : (
          <>
            {
              profile === 1 ? (
                <SelectWithLabel
                  color="rgba(0, 0, 0, 0.65)"
                  label="Cliente"
                  id="client"
                  options={clientOptions}
                  onChange={(e) => setAvtClient(e)}
                  defaultValue={avtClient}
                />
              ) : (<></>)
            }
            <TextAreaWithLabel
              defaultValue={modalReason}
              id="create"
              label="Motivo"
              onPaste={onPaste}
              placeholder="Explica el motivo del aviso, también puedes pegar imágenes o subirlas/arrastrarlas al botón de Subir imagen."
              reference={avtReasonRef}
            />
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={images}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {
              images.length >= 2 ? (null) : (
                <div>
                  <PlusOutlined />
                  <div className="ant-upload-text">Subir/Arrastrar imagen</div>
                </div>
              )
            }
            </Upload>
            {/* <button onClick={() => start()} disabled={isRecording}>
              Record
            </button>
            <button onClick={() => stop()} disabled={!isRecording}>
              Stop
            </button>
            <audio src={blobURL} controls="controls" /> */}
          </>
        )
      }
    </div>
  );
};

CreateAvt.propTypes = {
  loading: PropTypes.bool.isRequired,
  clientOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  setAvtClient: PropTypes.func.isRequired,
  avtClient: PropTypes.string.isRequired,
  profile: PropTypes.number.isRequired,
  onPaste: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  handlePreview: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  modalReason: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  avtReasonRef: PropTypes.any.isRequired,
};

export default CreateAvt;
