import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputWithLabel from '../../components/InputWithLabel/InputWithLabel';


const ClientsForm = (props) => {
  const { client, readOnly, setModifiedClient } = props;
  const {
    IDEmpWeb,
    Nombre,
    IDCliWeb,
    IDCliErp,
  } = client;

  const [id, setId] = useState(IDCliWeb);
  const [name, setName] = useState(Nombre);
  const [idEmp, setIdEmp] = useState(IDEmpWeb);
  const [idCliErp, setIdCliErp] = useState(IDCliErp);

  useEffect(() => {
    setModifiedClient({
      IDEmpWeb: idEmp,
      Nombre: name,
      IDCliWeb: id,
      IDCliErp: idCliErp,
    });
  }, [id, name, idEmp, idCliErp]);

  return (
    <>
      <div>
        <InputWithLabel
          readOnly={readOnly}
          value={id}
          label="Id"
          id="Id"
          onChange={setId}
        />
        <InputWithLabel
          readOnly={readOnly}
          value={name}
          label="Nombre"
          id="Nombre"
          onChange={setName}
        />
      </div>
      <div>
        <InputWithLabel
          readOnly={readOnly}
          value={idEmp}
          label="Id Empresa Web"
          id="Id Empresa Web"
          onChange={setIdEmp}
        />
        <InputWithLabel
          readOnly={readOnly}
          value={idCliErp}
          label="Id ERP"
          id="Id ERP"
          onChange={setIdCliErp}
        />
      </div>
    </>
  );
};

ClientsForm.propTypes = {
  client: PropTypes.shape({
    IDEmpWeb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    Nombre: PropTypes.string.isRequired,
    IDCliWeb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    IDCliErp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  setModifiedClient: PropTypes.func.isRequired,
};

export default ClientsForm;
