import React from 'react';
import './footer.css';
import PropTypes from 'prop-types';


const Footer = (props) => {
  const {
    firstColor, secondColor, company,
  } = props;

  const {
    Calle,
    CodPostal,
    // eslint-disable-next-line camelcase
    Poblacion,
    Provincia,
    Correo,
    // eslint-disable-next-line camelcase
    RS_Face,
    // eslint-disable-next-line camelcase
    RS_Instagram,
    // eslint-disable-next-line camelcase
    RS_Twitter,
    // eslint-disable-next-line camelcase
    RS_Youtube,
    // eslint-disable-next-line camelcase
    RS_Linkedin,
    Telefono,
    // eslint-disable-next-line camelcase
    Url_Maps,
  } = company;

  const companiesInfo = [
    {
      icon: 'gps',
      iconAlt: 'Icono localización',
      title: '¿Dónde estamos?',
      textLink: Calle,
      link: Url_Maps,
      moreInfo: `${CodPostal} · ${Poblacion} ${Provincia ? `(${Provincia})` : ''}`,
    },
    {
      icon: 'phone',
      iconAlt: 'Icono Teléfono',
      title: 'Puedes llamarnos al',
      textLink: Telefono,
      link: `tel:${Telefono}`,
    },
    {
      icon: 'email',
      iconAlt: 'Icono correo',
      title: 'O enviarnos un email a',
      textLink: Correo,
      link: `mailto:${Correo}`,
    },
  ];
  const companiesSocial = [
    {
      icon: 'facebook',
      iconAlt: 'Facebook',
      link: RS_Face,
    },
    {
      icon: 'twitter',
      iconAlt: 'Twitter',
      link: RS_Twitter,
    },
    {
      icon: 'instagram',
      iconAlt: 'Instagram',
      link: RS_Instagram,
    },
    {
      icon: 'linkedin',
      iconAlt: 'Linkedin',
      link: RS_Linkedin,
    },
    {
      icon: 'youtube',
      iconAlt: 'Youtube',
      link: RS_Youtube,
    },
  ];

  return (
    <footer style={{ backgroundColor: firstColor }}>
      <div className="Social">
        {
          companiesInfo.map((companyInfo) => {
            const {
              icon, iconAlt, title, textLink, link, moreInfo,
            } = companyInfo;
            return (
              <div className="Item" key={icon}>
                <div className="Icon">
                  <img src={`./images/${icon}.png`} alt={iconAlt} />
                </div>
                <div className="Description">
                  <h4 className="Title" style={{ color: secondColor }}>{title}</h4>
                  <div className="LinkContainer">
                    <a
                      style={{ color: secondColor }}
                      className="Link"
                      href={link}
                    >
                      {textLink}
                    </a>
                  </div>
                  <span className="MoreInfo" style={{ color: secondColor }}>{moreInfo}</span>
                </div>
              </div>
            );
          })
        }
      </div>
      {

      }
      <div className="Item">
        {
        companiesSocial.map((social) => {
          const { icon, iconAlt, link } = social;
          return link ? (
            <a className="Icon" href={link} key={icon}>
              <img src={`./images/${icon}.png`} alt={iconAlt} />
            </a>
          ) : (<></>);
        })
      }
      </div>
    </footer>
  );
};

Footer.propTypes = {
  firstColor: PropTypes.string.isRequired,
  secondColor: PropTypes.string.isRequired,
  company: PropTypes.shape({
    Logo: PropTypes.string,
    Calle: PropTypes.string,
    CodPostal: PropTypes.string,
    Poblacion: PropTypes.string,
    Provincia: PropTypes.string,
    Correo: PropTypes.string,
    RS_Face: PropTypes.string,
    RS_Instagram: PropTypes.string,
    RS_Twitter: PropTypes.string,
    RS_Linkedin: PropTypes.string,
    RS_Youtube: PropTypes.string,
    Telefono: PropTypes.string,
    Url_Maps: PropTypes.string,
  }),
};

Footer.defaultProps = {
  company: {
    Logo: '',
    Calle: '',
    CodPostal: '',
    Poblacion: '',
    Provincia: '',
    Correo: '',
    RS_Face: '',
    RS_Instagram: '',
    RS_Twitter: '',
    RS_Linkedin: '',
    RS_Youtube: '',
    Telefono: '',
    Url_Maps: '',
  },
};

export default Footer;
