import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './documents.css';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Empty from '../../../node_modules/antd/lib/empty';
import notification from '../../../node_modules/antd/lib/notification';
import Utils from '../../Utils';
import Api from '../../Api';
import Document from './Document';
import Modal from '../../../node_modules/antd/lib/modal';
import ModalFooter from '../ModalFooter/ModalFooter';
import Spin from '../../../node_modules/antd/lib/spin';

const {
  getUrl, buildHeaders, generateParams, convertHexadecimalToFile, deleteCookies,
} = Utils;
const { get } = Api;
const antIcon = <LoadingOutlined style={{ fontSize: 104 }} spin />;

const Documents = (props) => {
  const { documents, setIsLogged } = props;
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docIdentifier, setDocIdentifier] = useState('');
  const [pdf, setPdf] = useState('');
  const history = useHistory();

  const showError = (numDocCabec) => {
    notification.error({
      message: 'Error',
      description:
      `El pdf del documento ${numDocCabec} no se encuentra disponible`,
    });
  };

  const downloadPdf = (pdf, name) => {
    const buffer = Buffer.from(pdf, 'base64');
    const bufString = buffer.toString('hex');
    const hexadecimalPdf = convertHexadecimalToFile(bufString);
    const data = window.URL.createObjectURL(hexadecimalPdf);
    const link = document.createElement('a');
    link.href = data;
    link.download = `${name.replace(/ /g, '')}.pdf`;
    link.click();
  };

  const handleClick = async (docIdentifier) => {
    setIsModalVisible(true);
    setLoading(true);
    const {
      DocCabec, NumDocCabec, serfac, ideje,
    } = docIdentifier;
    const params = [
      { key: 'ideje', value: ideje },
      { key: 'serfac', value: serfac },
      { key: 'tipodoc', value: DocCabec },
      { key: 'numero', value: NumDocCabec },
      { key: 'url', value: getUrl() },
    ];
    const { pdf, error } = await get(`getpdf?${generateParams(params)}`,
      buildHeaders());
    if (error) {
      setIsLogged(false);
      deleteCookies();
      history.push('/login');
      return;
    }
    if (!pdf) {
      showError(NumDocCabec);
      return;
    }
    setPdf(pdf);
    setDocIdentifier(`${DocCabec}_${NumDocCabec}_${serfac}`);
    setLoading(false);
  };

  return (
    <div className="Documents">
      {
        documents.length > 0 ? (
          <>
            {documents.map((document) => {
              const {
                NumDocCabec,
                serfac,
                FechaCabec,
                NomClient,
                BaImpCabec,
                TotalCabec,
                DocCabec,
                ideje,
                Descripcion,
                SituacionCabec,
              } = document;
              const docIdentifier = {
                DocCabec, NumDocCabec, serfac, ideje,
              };
              return (
                <Document
                  key={NumDocCabec}
                  handleClick={handleClick}
                  NumDocCabec={NumDocCabec}
                  serfac={serfac}
                  FechaCabec={FechaCabec}
                  NomClient={NomClient}
                  BaImpCabec={BaImpCabec}
                  TotalCabec={TotalCabec}
                  DocCabec={DocCabec}
                  ideje={ideje}
                  docIdentifier={docIdentifier}
                  description={Descripcion}
                  SituacionCabec={SituacionCabec}
                />
              );
            })}
          </>
        ) : (
          <Empty
            description={(
              <span>
                No se ha encontrado ningún documento con estos filtros
              </span>
            )}
          />
        )
      }
      <Modal
        width="70%"
        height="100%"
        className="SearchModal"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={(
          <ModalFooter
            buttons={[
              { text: 'Descargar', onClick: () => (downloadPdf(pdf, docIdentifier)), disabled: loading },
            ]}
          />
        )}
      >
        {
         loading ? (<Spin indicator={antIcon} />) : (
           <iframe
             title="PDF"
             width="100%"
             height="100%"
             src={docIdentifier !== '' ? `data:application/pdf;base64,${pdf}` : ''}
           />
         )
       }
      </Modal>
    </div>
  );
};

Documents.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    BaImpCabec: PropTypes.number.isRequired,
    Descripcion: PropTypes.string,
    DocCabec: PropTypes.string.isRequired,
    FechaCabec: PropTypes.string.isRequired,
    NomClient: PropTypes.string.isRequired,
    NumDocCabec: PropTypes.number.isRequired,
    RzsClient: PropTypes.string.isRequired,
    TotalCabec: PropTypes.number.isRequired,
    ideje: PropTypes.number.isRequired,
    serfac: PropTypes.string.isRequired,
    SituacionCabec: PropTypes.string.isRequired,
  })).isRequired,
  setIsLogged: PropTypes.func.isRequired,
};

export default Documents;
