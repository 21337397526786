import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../node_modules/antd/lib/input';

const InputWithLabel = (props) => {
  const {
    label, id, type, value, onChange, className, readOnly, disabled, onKeyDown,
  } = props;
  return (
    <div>
      <label htmlFor={id}>{label}</label>
      <Input
        readOnly={readOnly}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={value}
        className={className}
        type={type}
        id={id}
        disabled={disabled}
        onKeyDown={(e) => onKeyDown(e)}
      />
    </div>
  );
};

InputWithLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

InputWithLabel.defaultProps = {
  type: 'text',
  className: '',
  readOnly: false,
  disabled: false,
  onKeyDown: (() => null),
};

export default InputWithLabel;
