import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../node_modules/antd/lib/select';

const SelectWithLabel = (props) => {
  const {
    label, id, options, onChange, defaultValue, disabled, color,
  } = props;
  return (
    <>
      <label style={{ color }} htmlFor={id}>{label}</label>
      <Select
        key={defaultValue}
        id={id}
        disabled={disabled}
        defaultValue={defaultValue}
        options={options}
        onChange={onChange}
      />
    </>
  );
};

SelectWithLabel.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

SelectWithLabel.defaultProps = {
  disabled: false,
  color: 'white',
};

export default SelectWithLabel;
