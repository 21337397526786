import React, { useState, useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Button from '../../../node_modules/antd/lib/button';
import DatePicker from '../../../node_modules/antd/lib/date-picker/index';
import Input from '../../../node_modules/antd/lib/input';
import Radio from '../../../node_modules/antd/lib/radio';
import Spin from '../../../node_modules/antd/lib/spin';
import Alert from '../../../node_modules/antd/lib/alert';
import notification from '../../../node_modules/antd/lib/notification';
import Tooltip from '../../../node_modules/antd/lib/tooltip';
import Api from '../../Api';
import Utils from '../../Utils';
import Charge from './Charge';
import Pager from '../Pager/Pager';
import './charges.css';

const { RangePicker } = DatePicker;
const { get } = Api;
const { buildHeaders, getUrl, generateParams } = Utils;

const Charges = (props) => {
  const { thirdColor, fourthColor, fifthColor } = props;
  const [mouseIsOverSearch, setMouseIsOverSearch] = useState(false);
  const [charges, setCharges] = useState([]);
  const [dates, setDates] = useState([null, null]);
  const [filterValues, setFilterValues] = useState({
    page: 1, elementsPerPage: 10, filter: '', dates: ',', paid: 'P',
  });
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const filterRef = useRef(null);
  useEffect(() => {
    setFilterValues({ ...filterValues, page: 1, dates });
  }, [dates]);
  useEffect(() => {
    (
      async () => {
        setLoading(true);
        const {
          page, elementsPerPage, filter, dates, paid,
        } = filterValues;
        const params = [
          { key: 'paid', value: paid },
          { key: 'pagina', value: page - 1 },
          { key: 'numero', value: elementsPerPage },
          { key: 'filtro', value: filter },
          { key: 'dates', value: dates },
        ];
        const chargesResponse = await get(`getcharges?${generateParams(params)}`, buildHeaders(), getUrl());
        const {
          error, mensaje, charges, numberCharges,
        } = chargesResponse;
        if (error) {
          notification.error({
            message: 'Error!',
            description: mensaje,
          });
        } else {
          setCharges(charges);
          setTotalItems(numberCharges);
        }
        setLoading(false);
      }
    )();
  }, [filterValues]);

  const handleSearchButtonClick = () => {
    setFilterValues({
      ...filterValues, dates, filter: filterRef.current.state.value, page: 1,
    });
  };

  const handleDateChange = (e) => {
    if (e) {
      setDates([
        e[0] ? e[0].format('YYYY-MM-DD') : '',
        e[1] ? e[1].format('YYYY-MM-DD') : '',
      ]);
    } else {
      setDates([]);
    }
  };

  const handlePaidChange = (e) => {
    setFilterValues({ ...filterValues, page: 1, paid: e.target.value });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <div className="Charges">
      <div className="Header">
        <Radio.Group defaultValue="P" buttonStyle="solid" onChange={handlePaidChange}>
          <Radio.Button value="P">Cobros pendientes</Radio.Button>
          <Radio.Button value="X">Cobros realizados</Radio.Button>
          <Radio.Button value="all">Todos</Radio.Button>
        </Radio.Group>
        <RangePicker
          allowEmpty={[true, true]}
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          placeholder={['Fecha inicio', 'Fecha fin']}
        />
        <div className="SearchCharges">
          <Input
            defaultValue=""
            ref={filterRef}
            id="ChargesFilter"
            onKeyDown={handleKeyDown}
          />
          <Tooltip title="Buscar">
            <Button
              onBlur={() => setMouseIsOverSearch(false)}
              onFocus={() => setMouseIsOverSearch(true)}
              onMouseOut={() => setMouseIsOverSearch(false)}
              onMouseOver={() => setMouseIsOverSearch(true)}
              style={{ color: fourthColor, backgroundColor: `${mouseIsOverSearch ? fifthColor : thirdColor}`, borderColor: `${mouseIsOverSearch ? fifthColor : thirdColor}` }}
              type="primary"
              shape="square"
              icon={<SearchOutlined />}
              onClick={handleSearchButtonClick}
            />
          </Tooltip>
        </div>
      </div>
      {
        loading ? (
          <Spin tip="Cargando...">
            <Alert
              message="Cargando"
              description="Estamos obteniendo la información del servidor..."
              type="info"
            />
          </Spin>
        ) : (
          <>
            <div className="Fields">
              <div>DOCUMENTO</div>
              <div>RECIBO</div>
              <div>MOV.</div>
              <div>FECHA</div>
              <div>IMPORTE</div>
            </div>
            <div className="Container">
              {
                charges.map((charge) => <Charge key={`${charge.serfac}${charge.NumDocMovCob}`} charge={charge} />)
              }
            </div>
          </>
        )
      }
      <Pager
        totalItems={totalItems}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
    </div>
  );
};

Charges.propTypes = {
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
};

export default Charges;
