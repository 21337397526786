import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Popover from '../../../node_modules/antd/lib/popover';

const Document = (props) => {
  const {
    NumDocCabec,
    serfac,
    FechaCabec,
    NomClient,
    BaImpCabec,
    TotalCabec,
    docIdentifier,
    handleClick,
    description,
    SituacionCabec,
    DocCabec,
  } = props;

  const status = {
    AVT: {
      N: 'PENDIENTE',
      T: 'EN CURSO',
      S: 'FINALIZADO',
    },
    FAC: {
      P: 'PND DE PAGO',
      X: 'PAGADA',
      A: 'AGRUPADA',
      V: 'DEVOLUCIÓN',
    },
  };

  const statusColor = {
    AVT: {
      N: 'rgb(255, 200, 200)',
      T: 'rgb(253, 227, 180)',
      S: '#e0fae7',
    },
    FAC: {
      P: 'rgb(255, 200, 200)',
      X: '#e0fae7',
      A: 'rgb(253, 227, 180)',
      V: 'red',
    },
  };

  return (
    <div className="Document">
      <div style={{ backgroundColor: statusColor[DocCabec] ? statusColor[DocCabec][SituacionCabec] : '' }}>
        <div className="Header">
          <div>
            {serfac}
            /
            {NumDocCabec}
          </div>
          <div>{moment(FechaCabec).format('DD/MM/YYYY')}</div>
        </div>
        <div className="Body">
          <div className="Info">
            <Popover content={NomClient}>
              <div className="Client">{NomClient}</div>
            </Popover>
            {/* <Popover content="Empresa">
              <div className="Client">Empresa</div>
            </Popover> */}
            <div className="Subject Status">{status[DocCabec] ? status[DocCabec][SituacionCabec] : ''}</div>
            {
              description ? (
                <Popover content={description}>
                  <div className="Subject">{description}</div>
                </Popover>
                // <Popover content="Esto es una descripción de prueba">
                //   <div className="Subject">Esto es una descripción de prueba</div>
                // </Popover>
              ) : (<div className="Subject" />)
            }
          </div>
          <div className="Info">
            <div>{`B.I. ${BaImpCabec} €`}</div>
            <div>{`TOTAL ${TotalCabec} €`}</div>
          </div>
        </div>
      </div>
      <div>
        <div
          onClick={(e) => handleClick(docIdentifier)}
        />
      </div>
    </div>
  );
};

Document.propTypes = {
  NumDocCabec: PropTypes.number.isRequired,
  serfac: PropTypes.string.isRequired,
  FechaCabec: PropTypes.string.isRequired,
  NomClient: PropTypes.string.isRequired,
  BaImpCabec: PropTypes.number.isRequired,
  TotalCabec: PropTypes.number.isRequired,
  docIdentifier: PropTypes.shape({
    DocCabec: PropTypes.string.isRequired,
    NumDocCabec: PropTypes.number.isRequired,
    ideje: PropTypes.number.isRequired,
    serfac: PropTypes.string.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  description: PropTypes.string,
  SituacionCabec: PropTypes.string.isRequired,
  DocCabec: PropTypes.string.isRequired,
};

Document.defaultProps = {
  description: '',
};

export default Document;
