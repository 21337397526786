import React from 'react';
import './home.css';
import PropTypes from 'prop-types';

const Home = ({
  homeTitle, homeText, backgroundHome, backgroundSquareHome,
}) => (
  <div style={{ backgroundImage: `url('${backgroundHome}')` }} className="Home">
    <div style={{ backgroundImage: `url('${backgroundSquareHome}')` }} className="Welcome">
      <div className="Title">{homeTitle}</div>
      <div className="Info">{homeText}</div>
    </div>
  </div>
);

Home.propTypes = {
  homeTitle: PropTypes.string.isRequired,
  homeText: PropTypes.string.isRequired,
  backgroundHome: PropTypes.string.isRequired,
  backgroundSquareHome: PropTypes.string.isRequired,
};

export default Home;
