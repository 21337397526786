import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import notification from '../../../node_modules/antd/lib/notification';
import Api from '../../Api';
import './companies.css';
import Utils from '../../Utils';
import Button from '../../../node_modules/antd/lib/button';
import SelectWithLabel from '../../components/SelectWithLabel/SelectWithLabel';
import CompaniesInfo from './CompaniesInfo';

const {
  buildHeaders, deleteCookies,
} = Utils;
const { get, post } = Api;

const Companies = (props) => {
  const {
    profile,
    setIsLogged,
    setHomeTitle,
    setHomeText,
    thirdColor,
    fourthColor,
    fifthColor,
    setFirstColor,
    setSecondColor,
    setThirdColor,
    setFourthColor,
    setFifthColor,
    setLogo,
    setBackgroundHome,
    setBackgroundSquareHome,
  } = props;

  const emptyCompany = {
    IDEmpWeb: '',
    Nombre: '',
    CIF: '',
    IDEmpErp: '',
    TipoEmpresa: '',
    URLNode: '',
    Logo: '',
    Color1: '',
    Color2: '',
    Color3: '',
    Color4: '',
    Color5: '',
    Calle: '',
    CodPostal: '',
    Home_Html: '',
    Poblacion: '',
    Provincia: '',
    Correo: '',
    RS_Face: '',
    RS_Instagram: '',
    RS_Twitter: '',
    RS_Youtube: '',
    RS_Linkedin: '',
    Telefono: '',
    Url_Maps: '',
    IdEjeEmp: '',
    Texto1_home: '',
    Texto2_home: '',
    fondo_home: '',
    fondo_cuadro_home: '',
  };

  const history = useHistory();
  const [mouseOverNewCompany, setMouseOverNewCompany] = useState(false);
  const [mouseOverCreateOrUpdateCompany, setMouseOverCreateOrUpdateCompany] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(emptyCompany);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [modifiedCompany, setModifiedCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [creatingCompany, setCreatingCompany] = useState(false);

  useEffect(() => {
    (async () => {
      const companiesCallResponse = await get('getcompanies',
        buildHeaders());
      const { error, companies } = companiesCallResponse;
      if (error) {
        setIsLogged(false);
        deleteCookies();
        history.push('/login');
        return;
      }
      setCompanies(companies);
      setCompany(companies[selectedCompany]);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (companies.length === 0) {
      return;
    }
    setCompaniesOptions(companies.map((company) => ({
      label: company.Nombre, value: company.IDEmpWeb,
    })));
    setCompany(companies[selectedCompany]);
    setLoading(false);
  }, [companies, selectedCompany]);

  useEffect(() => {
    setLoading(false);
  }, [company]);

  const companySelectOnChange = (option) => {
    let index = -1;
    companies.forEach((company, i) => {
      if (company.IDEmpWeb === option) {
        index = i;
      }
    });
    setSelectedCompany(index);
    setLoading(true);
  };

  const onClickNewButton = () => {
    setCreatingCompany(true);
    setCompany(emptyCompany);
    setModifiedCompany(emptyCompany);
    setLoading(true);
  };

  const onClickCancelButton = () => {
    setCreatingCompany(false);
    setCompany(companies[selectedCompany]);
    setModifiedCompany(companies[selectedCompany]);
  };

  const onClickSaveButton = (save) => {
    (async () => {
      const createCompanyResponse = await post('createcompany', modifiedCompany,
        buildHeaders());
      const { error, mensaje } = createCompanyResponse;
      if (error) {
        notification.error({
          message: 'Error',
          description: mensaje,
        });
        return;
      }
      setLoading(false);
      const notificationDescription = save ? 'Se ha creado la nueva empresa con éxito!' : 'Se han actualizado los datos';
      notification.success({
        message: 'Éxito!',
        description: notificationDescription,
      });
    })();
  };

  return (
    <div className="Companies">
      {
        profile === 0 ? (
          <div className="Buttons">
            {
            !creatingCompany ? (
              <Button
                style={{
                  backgroundColor: mouseOverNewCompany ? fifthColor : thirdColor,
                  borderColor: mouseOverNewCompany ? fifthColor : thirdColor,
                  fontWeight: 'bold',
                  color: fourthColor,
                }}
                onBlur={() => setMouseOverNewCompany(false)}
                onFocus={() => setMouseOverNewCompany(true)}
                onMouseOut={() => setMouseOverNewCompany(false)}
                onMouseOver={() => setMouseOverNewCompany(true)}
                type="default"
                onClick={() => onClickNewButton()}
              >
                Nueva empresa
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: mouseOverNewCompany ? fifthColor : thirdColor,
                  borderColor: mouseOverNewCompany ? fifthColor : thirdColor,
                  fontWeight: 'bold',
                  color: fourthColor,
                }}
                onBlur={() => setMouseOverNewCompany(false)}
                onFocus={() => setMouseOverNewCompany(true)}
                onMouseOut={() => setMouseOverNewCompany(false)}
                onMouseOver={() => setMouseOverNewCompany(true)}
                type="default"
                onClick={() => onClickCancelButton()}
              >
                Cancelar
              </Button>
            )
          }
          </div>
        ) : (<></>)
      }
      {
        !loading && companiesOptions.length > 1 && !creatingCompany
          ? (
            <SelectWithLabel
              label="Companies"
              id="Companies"
              options={companiesOptions}
              defaultValue={companiesOptions[selectedCompany].value}
              onChange={companySelectOnChange}
            />
          ) : (<></>)
      }
      {
        !loading ? (
          <CompaniesInfo
            company={company}
            setModifiedCompany={setModifiedCompany}
            key={company.IDEmpWeb}
            setHomeTitleApp={setHomeTitle}
            setHomeTextApp={setHomeText}
            setFirstColor={setFirstColor}
            setSecondColor={setSecondColor}
            setThirdColor={setThirdColor}
            setFourthColor={setFourthColor}
            setFifthColor={setFifthColor}
            setLogo={setLogo}
            setBackgroundHome={setBackgroundHome}
            setBackgroundSquareHome={setBackgroundSquareHome}
          />
        ) : (<></>)
      }
      {
      profile === 0 || profile === 1 ? (
        <div className="Buttons Right">
          {
            !creatingCompany ? (
              <Button
                style={{
                  backgroundColor: mouseOverCreateOrUpdateCompany ? fifthColor : thirdColor,
                  borderColor: mouseOverCreateOrUpdateCompany ? fifthColor : thirdColor,
                  fontWeight: 'bold',
                  color: fourthColor,
                }}
                onBlur={() => setMouseOverCreateOrUpdateCompany(false)}
                onFocus={() => setMouseOverCreateOrUpdateCompany(true)}
                onMouseOut={() => setMouseOverCreateOrUpdateCompany(false)}
                onMouseOver={() => setMouseOverCreateOrUpdateCompany(true)}
                type="default"
                onClick={() => onClickSaveButton(false)}
              >
                Actualizar datos
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: mouseOverCreateOrUpdateCompany ? fifthColor : thirdColor,
                  borderColor: mouseOverCreateOrUpdateCompany ? fifthColor : thirdColor,
                  fontWeight: 'bold',
                  color: fourthColor,
                }}
                onBlur={() => setMouseOverCreateOrUpdateCompany(false)}
                onFocus={() => setMouseOverCreateOrUpdateCompany(true)}
                onMouseOut={() => setMouseOverCreateOrUpdateCompany(false)}
                onMouseOver={() => setMouseOverCreateOrUpdateCompany(true)}
                type="default"
                onClick={() => onClickSaveButton(true)}
              >
                Guardar empresa
              </Button>
            )
        }
        </div>
      ) : (<></>)
    }
    </div>
  );
};

Companies.propTypes = {
  profile: PropTypes.number.isRequired,
  setIsLogged: PropTypes.func.isRequired,
  setLogo: PropTypes.func.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
  setFirstColor: PropTypes.func.isRequired,
  setSecondColor: PropTypes.func.isRequired,
  setThirdColor: PropTypes.func.isRequired,
  setFourthColor: PropTypes.func.isRequired,
  setFifthColor: PropTypes.func.isRequired,
  setHomeTitle: PropTypes.func.isRequired,
  setHomeText: PropTypes.func.isRequired,
  setBackgroundHome: PropTypes.func.isRequired,
  setBackgroundSquareHome: PropTypes.func.isRequired,
};

export default Companies;
