import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CaretRightOutlined } from '@ant-design/icons';
import Collapse from 'antd/lib/collapse';
import InputWithLabel from '../../components/InputWithLabel/InputWithLabel';
import Utils from '../../Utils';
import TextAreaWithLabel from '../../components/TextAreaWithLabel/TextAreaWithLabel';

const {
  rgbToHex, hexToRgb,
} = Utils;

const { Panel } = Collapse;


const CompaniesInfo = (props) => {
  const {
    company,
    setModifiedCompany,
    setHomeTitleApp,
    setHomeTextApp,
    setFirstColor: setFirstColorApp,
    setSecondColor: setSecondColorApp,
    setThirdColor: setThirdColorApp,
    setFourthColor: setFourthColorApp,
    setFifthColor: setFifthColorApp,
    setLogo: setLogoApp,
    setBackgroundHome: setAppBackgroundHome,
    setBackgroundSquareHome: setAppBackgroundSquareHome,
  } = props;

  const {
    IDEmpWeb,
    Nombre,
    CIF,
    IDEmpErp,
    TipoEmpresa,
    URLNode,
    Logo,
    Color1,
    Color2,
    Color3,
    Color4,
    Color5,
    Calle,
    CodPostal,
    // eslint-disable-next-line camelcase
    Home_Html,
    Poblacion,
    Provincia,
    Correo,
    // eslint-disable-next-line camelcase
    RS_Face,
    // eslint-disable-next-line camelcase
    RS_Instagram,
    // eslint-disable-next-line camelcase
    RS_Twitter,
    // eslint-disable-next-line camelcase
    RS_Youtube,
    // eslint-disable-next-line camelcase
    RS_Linkedin,
    Telefono,
    // eslint-disable-next-line camelcase
    Url_Maps,
    IdEjeEmp,
    // eslint-disable-next-line camelcase
    Texto1_Home,
    // eslint-disable-next-line camelcase
    Texto2_Home,
    // eslint-disable-next-line camelcase
    fondo_home,
    // eslint-disable-next-line camelcase
    fondo_cuadro_home,
  } = company;

  const homeHtmlRef = useRef(null);

  const [id, setId] = useState(IDEmpWeb);
  const [name, setName] = useState(Nombre);
  const [cif, setCif] = useState(CIF);
  const [idErp, setIdErp] = useState(IDEmpErp);
  const [clientType, setClientType] = useState(TipoEmpresa);
  const [apiUrl, setApiUrl] = useState(URLNode);
  const [logo, setLogo] = useState(Logo);
  const [adress, setAdress] = useState(Calle);
  const [postalCode, setPostalCode] = useState(CodPostal);
  const [homeHtml, setHomeHtml] = useState(Home_Html);
  const [town, setTown] = useState(Poblacion);
  const [province, setProvince] = useState(Provincia);
  const [facebook, setFacebook] = useState(RS_Face);
  const [instagram, setInstagram] = useState(RS_Instagram);
  const [twitter, setTwitter] = useState(RS_Twitter);
  const [youtube, setYoutube] = useState(RS_Youtube);
  const [linkedin, setLinkedin] = useState(RS_Linkedin);
  const [phone, setPhone] = useState(Telefono);
  const [mapsUrl, setMapsUrl] = useState(Url_Maps);
  const [email, setEmail] = useState(Correo);
  const [startingIdeje, setStartingIdeje] = useState(IdEjeEmp);
  const [firstColor, setFirstColor] = useState(Color1);
  const [secondColor, setSecondColor] = useState(Color2);
  const [thirdColor, setThirdColor] = useState(Color3);
  const [fourthColor, setFourthColor] = useState(Color4);
  const [fifthColor, setFifthColor] = useState(Color5);
  const [homeTitle, setHomeTitle] = useState(Texto1_Home);
  const [homeText, setHomeText] = useState(Texto2_Home);
  const [backgroundHome, setBackgroundHome] = useState(fondo_home);
  const [backgroundSquareHome, setBackgroundSquareHome] = useState(fondo_cuadro_home);

  const handleFirstColorChange = (value) => {
    setFirstColor(value);
    setFirstColorApp(value);
  };

  const handleSecondColorChange = (value) => {
    setSecondColor(value);
    setSecondColorApp(value);
  };

  const handleThirdColorChange = (value) => {
    setThirdColor(value);
    setThirdColorApp(value);
  };

  const handleFourthColorChange = (value) => {
    setFourthColor(value);
    setFourthColorApp(value);
  };

  const handleFifthColorChange = (value) => {
    setFifthColor(value);
    setFifthColorApp(value);
  };

  const handleLogoChange = (value) => {
    setLogo(value);
    setLogoApp(value);
  };

  const handleHomeTitleChange = (value) => {
    setHomeTitleApp(value);
    setHomeTitle(value);
  };

  const handleHomeTextChange = (value) => {
    setHomeTextApp(value);
    setHomeText(value);
  };

  const handleBackgroundHomeChange = (value) => {
    setBackgroundHome(value);
    setAppBackgroundHome(value);
  };

  const handleBackgroundSquareHomeChange = (value) => {
    setBackgroundSquareHome(value);
    setAppBackgroundSquareHome(value);
  };

  useEffect(() => {
    setModifiedCompany({
      IDEmpWeb: id,
      Nombre: name,
      CIF: cif,
      IDEmpErp: idErp,
      TipoEmpresa: clientType,
      URLNode: apiUrl,
      Logo: logo,
      Color1: firstColor[0] === '#' ? hexToRgb(firstColor) : firstColor,
      Color2: secondColor[0] === '#' ? hexToRgb(secondColor) : secondColor,
      Color3: thirdColor[0] === '#' ? hexToRgb(thirdColor) : thirdColor,
      Color4: fourthColor[0] === '#' ? hexToRgb(fourthColor) : fourthColor,
      Color5: fifthColor[0] === '#' ? hexToRgb(fifthColor) : fifthColor,
      Calle: adress,
      CodPostal: postalCode,
      // eslint-disable-next-line camelcase
      Home_Html: homeHtml,
      Poblacion: town,
      Provincia: province,
      Correo: email,
      // eslint-disable-next-line camelcase
      RS_Face: facebook,
      // eslint-disable-next-line camelcase
      RS_Instagram: instagram,
      // eslint-disable-next-line camelcase
      RS_Twitter: twitter,
      // eslint-disable-next-line camelcase
      RS_Youtube: youtube,
      RS_Linkedin: linkedin,
      Telefono: phone,
      // eslint-disable-next-line camelcase
      Url_Maps: mapsUrl,
      IdEjeEmp: startingIdeje,
      Texto1_Home: homeTitle,
      Texto2_Home: homeText,
      fondo_home: backgroundHome,
      fondo_cuadro_home: backgroundSquareHome,
    });
  }, [id, name, cif, idErp, clientType, apiUrl, logo,
    firstColor, secondColor, thirdColor,
    fourthColor, fifthColor, adress, postalCode, homeHtml, town, province,
    email, facebook, instagram, twitter, youtube, linkedin,
    phone, mapsUrl, startingIdeje, homeText, homeTitle, backgroundHome, backgroundSquareHome,
  ]);

  return (
    <div className="Container">
      <InputWithLabel
        value={name}
        label="Nombre"
        id="Nombre"
        onChange={setName}
      />
      <InputWithLabel
        value={cif}
        label="Cif"
        id="Cif"
        onChange={setCif}
      />
      <InputWithLabel
        value={phone}
        label="Teléfono"
        id="Teléfono"
        onChange={setPhone}
      />
      <InputWithLabel
        value={adress}
        label="Calle"
        id="Calle"
        onChange={setAdress}
      />
      <InputWithLabel
        value={postalCode}
        label="Código postal"
        id="Código postal"
        onChange={setPostalCode}
      />
      <InputWithLabel
        value={town}
        label="Población"
        id="Población"
        onChange={setTown}
      />
      <InputWithLabel
        value={province}
        label="Provincia"
        id="Provincia"
        onChange={setProvince}
      />
      <InputWithLabel
        value={mapsUrl}
        label="Url maps"
        id="Url maps"
        onChange={setMapsUrl}
      />
      <InputWithLabel
        value={email}
        label="Email"
        id="Email"
        onChange={setEmail}
      />
      <Collapse
        bordered={false}
        defaultActiveKey={[]}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
      >
        <Panel header="Redes sociales" key="1">
          <InputWithLabel
            value={facebook}
            label="Facebook"
            id="Facebook"
            onChange={setFacebook}
          />
          <InputWithLabel
            value={twitter}
            label="Twitter"
            id="Twitter"
            onChange={setTwitter}
          />
          <InputWithLabel
            value={instagram}
            label="Instagram"
            id="Instagram"
            onChange={setInstagram}
          />
          <InputWithLabel
            value={youtube}
            label="Youtube"
            id="Youtube"
            onChange={setYoutube}
          />
          <InputWithLabel
            value={linkedin}
            label="Linkedin"
            id="Linkedin"
            onChange={setLinkedin}
          />
        </Panel>
        <Panel header="Diseño" key="2">
          <InputWithLabel
            value={logo}
            label="Link del logo de la empresa o en su defecto su base64"
            id="logo"
            onChange={handleLogoChange}
          />
          <TextAreaWithLabel
            defaultValue={homeHtml}
            id="home"
            label="Diseño del home"
            placeholder="Inserta un html que sustituirá el home."
            reference={homeHtmlRef}
          />
          <div className="Colors">
            <InputWithLabel
              type="color"
              value={rgbToHex(firstColor)}
              label="Fondo del header y del footer"
              id="firstColor"
              onChange={handleFirstColorChange}
            />
            <InputWithLabel
              type="color"
              value={rgbToHex(secondColor)}
              label="Letras del header y del footer"
              id="secondColor"
              onChange={handleSecondColorChange}
            />
            <InputWithLabel
              type="color"
              value={rgbToHex(thirdColor)}
              label="Fondo de los botones y del filtro y color de los links del footer"
              id="thirdColor"
              onChange={handleThirdColorChange}
            />
            <InputWithLabel
              type="color"
              value={rgbToHex(fourthColor)}
              label="Letras de los botones y del filtro"
              id="fourthColor"
              onChange={handleFourthColorChange}
            />
            <InputWithLabel
              type="color"
              value={rgbToHex(fifthColor)}
              label="Fondo de los botones cuando tienes el ratón encima"
              id="fifthColor"
              onChange={handleFifthColorChange}
            />
            <InputWithLabel
              value={homeTitle}
              label="Introduce el primer texto del home"
              id="homeTitle"
              onChange={handleHomeTitleChange}
            />
            <InputWithLabel
              value={homeText}
              label="Introduce el segundo texto del home"
              id="homeText"
              onChange={handleHomeTextChange}
            />
            <InputWithLabel
              value={backgroundHome}
              label="Introduce la imagen de fondo del home"
              id="homeText"
              onChange={handleBackgroundHomeChange}
            />
            <InputWithLabel
              value={backgroundSquareHome}
              label="Introduce la imagen de fondo del cuadro de home"
              id="homeText"
              onChange={handleBackgroundSquareHomeChange}
            />
          </div>
        </Panel>
        <Panel header="Información del servidor" key="3">
          <InputWithLabel
            disabled
            value={apiUrl}
            label="Api"
            id="Api"
            onChange={setApiUrl}
          />
          <InputWithLabel
            disabled
            value={startingIdeje}
            label="Ejercicio inicial"
            id="Ejercicio inicial"
            onChange={setStartingIdeje}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

CompaniesInfo.propTypes = {
  company: PropTypes.shape({
    IDEmpWeb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    Nombre: PropTypes.string.isRequired,
    CIF: PropTypes.string.isRequired,
    IDEmpErp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    TipoEmpresa: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    URLNode: PropTypes.string.isRequired,
    Logo: PropTypes.string.isRequired,
    Color1: PropTypes.string.isRequired,
    Color2: PropTypes.string.isRequired,
    Color3: PropTypes.string.isRequired,
    Color4: PropTypes.string.isRequired,
    Color5: PropTypes.string.isRequired,
    Calle: PropTypes.string.isRequired,
    CodPostal: PropTypes.string.isRequired,
    Home_Html: PropTypes.string.isRequired,
    Poblacion: PropTypes.string.isRequired,
    Provincia: PropTypes.string.isRequired,
    Correo: PropTypes.string.isRequired,
    RS_Face: PropTypes.string.isRequired,
    RS_Instagram: PropTypes.string.isRequired,
    RS_Twitter: PropTypes.string.isRequired,
    RS_Linkedin: PropTypes.string.isRequired,
    RS_Youtube: PropTypes.string.isRequired,
    Telefono: PropTypes.string.isRequired,
    Url_Maps: PropTypes.string.isRequired,
    IdEjeEmp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    Texto1_Home: PropTypes.string.isRequired,
    Texto2_Home: PropTypes.string.isRequired,
    fondo_home: PropTypes.string.isRequired,
    fondo_cuadro_home: PropTypes.string.isRequired,
  }).isRequired,
  setModifiedCompany: PropTypes.func.isRequired,
  setFirstColor: PropTypes.func.isRequired,
  setSecondColor: PropTypes.func.isRequired,
  setThirdColor: PropTypes.func.isRequired,
  setFourthColor: PropTypes.func.isRequired,
  setFifthColor: PropTypes.func.isRequired,
  setLogo: PropTypes.func.isRequired,
  setHomeTitleApp: PropTypes.func.isRequired,
  setHomeTextApp: PropTypes.func.isRequired,
  setBackgroundHome: PropTypes.func.isRequired,
  setBackgroundSquareHome: PropTypes.func.isRequired,
};

export default CompaniesInfo;
