const ERP_URL = process.env.NODE_ENV === 'production' ? 'https://b2b.itandp.es/' : 'http://192.168.0.29:5001/';
// const ERP_URL = 'http://b2b.itandp.es/';
// const ERP_URL = 'http://localhost:5001/';
// const ERP_URL = 'http://192.168.0.10:5001/';

const Api = {
  async post(method, body, headers, url = ERP_URL) {
    const CONFIG = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(`${url}${method}`, CONFIG);
    return response.json();
  },
  async get(method, headers, url = ERP_URL) {
    const CONFIG = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
    };

    const response = await fetch(`${url}${method}`, CONFIG);
    return response.json();
  },
};

export default Api;
