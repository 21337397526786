import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Api from '../../Api';
import './users.css';
import Spin from '../../../node_modules/antd/lib/spin';
import Utils from '../../Utils';
import Button from '../../../node_modules/antd/lib/button';
import ListUsers from './ListUsers';
import InputWithLabel from '../../components/InputWithLabel/InputWithLabel';
import Pager from '../../components/Pager/Pager';
import Modal from '../../../node_modules/antd/lib/modal';
import ModalFooter from '../../components/ModalFooter/ModalFooter';
import notification from '../../../node_modules/antd/lib/notification';
import SelectWithLabel from '../../components/SelectWithLabel/SelectWithLabel';
import Alert from '../../../node_modules/antd/lib/alert';
import Checkbox from '../../../node_modules/antd/lib/checkbox';

const {
  buildHeaders, deleteCookies, generateParams,
} = Utils;
const { get, post } = Api;

const Users = (props) => {
  const {
    setIsLogged, profile, thirdColor, fourthColor, fifthColor,
  } = props;

  const history = useHistory();
  const [displayModal, setDisplayModal] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [filterValues, setFilterValues] = useState({ filtro: '', page: 1, elementsPerPage: 10 });
  const [totalItems, setTotalItems] = useState(0);
  const [users, setUsers] = useState([]);
  const [filterInput, setFilterInput] = useState('');
  const [clients, setClients] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(0);
  const [loading, setLoading] = useState(true);
  const [mouseOver, setMouseOver] = useState(false);
  const [seeOrders, setSeeOrders] = useState(false);
  const [createOrders, setCreateOrders] = useState(false);
  const [seeInvoices, setSeeInvoices] = useState(false);
  const [createInvoices, setCreateInvoices] = useState(false);
  const [seeTickets, setSeeTickets] = useState(true);
  const [createTickets, setCreateTickets] = useState(true);
  const [seeBudgets, setSeeBudgets] = useState(false);
  const [createBudgets, setCreateBudgets] = useState(false);
  const [seeDeliveryNotes, setSeeDeliveryNotes] = useState(false);
  const [seePrices, setSeePrices] = useState(true);
  const [createDeliveryNotes, setCreateDeliveryNotes] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [userSelect, setUserSelected] = useState('');

  useEffect(() => {
    (async () => {
      const clientsCallResponse = await get('getclients',
        buildHeaders());
      const { error, clients } = clientsCallResponse;
      if (error) {
        setIsLogged(false);
        deleteCookies();
        history.push('/login');
        return;
      }
      setClients(clients);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (clients.length === 0) {
      return;
    }
    setClientOptions(clients.map((client) => ({
      label: client.RzsClient, value: client.CodClient,
    })));
  }, [clients]);

  const clientSelectOnChange = (option) => {
    let index = -1;
    clients.forEach((client, i) => {
      if (client.CodClient === option) {
        index = i;
      }
    });
    setSelectedClient(index);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const {
        page, elementsPerPage, filtro,
      } = filterValues;
      const params = [
        { key: 'pagina', value: page - 1 },
        { key: 'numero', value: elementsPerPage },
        { key: 'filtro', value: encodeURIComponent(filtro) },
      ];
      const getUsers = await get(`geterpclients?${generateParams(params)}`,
        buildHeaders());
      const {
        error, users, numberUsers,
      } = getUsers;
      if (error) {
        setIsLogged(false);
        deleteCookies();
        history.push('/login');
      }
      setUsers(users);
      setTotalItems(numberUsers);
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [filterValues]);

  const addUser = () => {
    setNewUsername('');
    setSeeTickets(true);
    setCreateTickets(true);
    setSeeInvoices(false);
    setCreateInvoices(false);
    setSeeOrders(false);
    setCreateOrders(false);
    setSeeBudgets(false);
    setCreateBudgets(false);
    setSeeDeliveryNotes(false);
    setSeePrices(true);
    setCreateDeliveryNotes(false);
    setDisplayModal(true);
    setIsCreatingUser(true);
  };

  const managePermissions = (username) => {
    (
      async () => {
        const params = [
          { key: 'user', value: username },
        ];
        const getPermissions = await get(`getpermissions?${generateParams(params)}`, buildHeaders());
        const { error, user, mensaje } = getPermissions;
        if (error) {
          notification.error({
            message: 'Error!',
            description: mensaje,
          });
        } else {
          const {
            Ver_Avt,
            Crear_Avt,
            Ver_Fac,
            Crear_Fac,
            Ver_Ped,
            Crear_Ped,
            Ver_Pre,
            Crear_Pre,
            Ver_Alb,
            Crear_Alb,
            Ver_Precios,
          } = user;
          setSeeTickets(Ver_Avt);
          setCreateTickets(Crear_Avt);
          setSeeInvoices(Ver_Fac);
          setCreateInvoices(Crear_Fac);
          setSeeOrders(Ver_Ped);
          setCreateOrders(Crear_Ped);
          setSeeBudgets(Ver_Pre);
          setCreateBudgets(Crear_Pre);
          setSeeDeliveryNotes(Ver_Alb);
          setCreateDeliveryNotes(Crear_Alb);
          setSeePrices(Ver_Precios);
          setDisplayModal(true);
          setIsCreatingUser(false);
          setUserSelected(username);
        }
      }
    )();
  };

  const closeModal = () => {
    setDisplayModal(false);
  };

  const resetPassword = (username) => {
    setLoading(true);
    (async () => {
      const userToReset = username;
      const body = {
        userToReset,
      };
      const resetPasswordCall = await post('resetpassword', body,
        buildHeaders());
      const { error } = resetPasswordCall;
      if (error) {
        setIsLogged(false);
        deleteCookies();
        history.push('/login');
        return;
      }
      notification.success({
        message: 'Success!',
        duration: 7,
        description:
      `El usuario ${userToReset} tendrá que iniciar sesión con el campo de la contraseña vacío para poder actualizarla`,
      });
      setLoading(false);
    })();
  };

  const createUser = () => {
    setLoading(true);
    (
      async () => {
        const body = {
          Nombre: newUsername,
          IDCliErp: clientOptions[selectedClient].value,
          seeOrders,
          createOrders,
          seeInvoices,
          createInvoices,
          seeTickets,
          createTickets,
          seeBudgets,
          createBudgets,
          seeDeliveryNotes,
          createDeliveryNotes,
        };
        const response = await post('createuser', body,
          buildHeaders());
        const { error, mensaje } = response;
        if (error) {
          notification.error({
            message: 'Error!',
            duration: 7,
            description: mensaje,
          });
        } else {
          notification.success({
            message: 'Success!',
            duration: 7,
            description:
      `El usuario ${newUsername} ha sido creado con éxito. Tendrá que iniciar sesión con el campo de la contraseña vacío para poder actualizarla`,
          });
        }
        setFilterValues({ ...filterValues });
      }
    )();
    closeModal();
  };

  const updateUsersPermissions = () => {
    (
      async () => {
        const body = {
          seeOrders,
          createOrders,
          seeInvoices,
          createInvoices,
          seeTickets,
          createTickets,
          seeBudgets,
          createBudgets,
          seeDeliveryNotes,
          createDeliveryNotes,
          user: userSelect,
        };
        const updatePermissionsResponse = await post('updatepermissions', body, buildHeaders());
        const { error, mensaje } = updatePermissionsResponse;
        if (error) {
          notification.error({
            message: 'Error!',
            duration: 7,
            description: mensaje,
          });
        } else {
          notification.success({
            message: 'Success!',
            duration: 7,
            description:
      `Has actualizado los permisos del usuario ${userSelect}.`,
          });
        }
      }
    )();
    closeModal();
  };

  const deleteUser = (userToDelete) => {
    setLoading(true);
    (
      async () => {
        const body = {
          userToDelete,
        };
        const response = await post('deleteuser', body,
          buildHeaders());
        const { error, mensaje } = response;
        if (error) {
          notification.error({
            message: 'Error!',
            duration: 7,
            description: mensaje,
          });
        } else {
          notification.success({
            message: 'Success!',
            duration: 7,
            description:
      `El usuario ${userToDelete} ha sido eliminado con éxito.`,
          });
        }
        setFilterValues({ ...filterValues });
      }
    )();
    closeModal();
  };

  const changeFilter = () => {
    setLoading(true);
    setFilterValues({ ...filterValues, filtro: filterInput, page: 1 });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      changeFilter();
    }
  };

  const Permissions = () => (
    <div className="Permissions">
      <div>
        <div className="Title">Permisos</div>
      </div>
      <div>
        <div className="See">
          <div className="Title">Permisos de lectura</div>
          <div>
            <Checkbox
              onChange={(e) => setSeeTickets(e.target.checked)}
              checked={seeTickets}
            >
              Ver avisos de taller
            </Checkbox>
            <Checkbox
              onChange={(e) => setSeeInvoices(e.target.checked)}
              checked={seeInvoices}
            >
              Ver facturas
            </Checkbox>
            <Checkbox
              onChange={(e) => setSeeOrders(e.target.checked)}
              checked={seeOrders}
            >
              Ver pedidos
            </Checkbox>
            <Checkbox
              onChange={(e) => setSeeBudgets(e.target.checked)}
              checked={seeBudgets}
            >
              Ver presupuestos
            </Checkbox>
            <Checkbox
              onChange={(e) => setSeeDeliveryNotes(e.target.checked)}
              checked={seeDeliveryNotes}
            >
              Ver albaranes
            </Checkbox>
            <Checkbox
              onChange={(e) => setSeePrices(e.target.checked)}
              checked={seePrices}
            >
              Ver precios
            </Checkbox>
          </div>
        </div>
        <div className="Create">
          <div className="Title">Permisos de creación</div>
          <div>
            <Checkbox
              onChange={(e) => setCreateTickets(e.target.checked)}
              checked={createTickets}
            >
              Crear avisos de taller
            </Checkbox>
            <Checkbox
              onChange={(e) => (setCreateInvoices(e.target.checked))}
              checked={createInvoices}
            >
              Crear facturas
            </Checkbox>
            <Checkbox
              onChange={(e) => setCreateOrders(e.target.checked)}
              checked={createOrders}
            >
              Crear pedidos
            </Checkbox>
            <Checkbox
              onChange={(e) => setCreateBudgets(e.target.checked)}
              checked={createBudgets}
            >
              Crear presupuestos
            </Checkbox>
            <Checkbox
              onChange={(e) => setCreateDeliveryNotes(e.target.checked)}
              checked={createDeliveryNotes}
            >
              Crear albaranes
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Users">
      {
        profile !== 4 ? (
          <div className="Buttons">
            <div>
              {profile === 1 ? (
                <Button
                  style={{
                    backgroundColor: mouseOver ? fifthColor : thirdColor,
                    borderColor: mouseOver ? fifthColor : thirdColor,
                    fontWeight: 'bold',
                    color: fourthColor,
                  }}
                  onBlur={() => (null)}
                  onFocus={() => (null)}
                  onMouseOut={() => setMouseOver(false)}
                  onMouseOver={() => setMouseOver(true)}
                  onClick={addUser}
                  type="default"
                >
                  Crear usuario
                </Button>
              ) : (<></>)}
            </div>
          </div>
        ) : (<></>)
      }
      <div className="UsersSearch">
        <InputWithLabel
          value={filterInput}
          label="Filtra por empresa o usuario"
          id="ArticleFilter"
          onChange={setFilterInput}
          onKeyDown={handleKeyDown}
        />
        <Button onClick={changeFilter}>Buscar</Button>
      </div>
      {
        loading ? (
          <div className="UsersSpin">
            <Spin tip="Cargando...">
              <Alert
                message="Espera"
                description="Cargando información, tomará unos segundos..."
                type="info"
              />
            </Spin>
          </div>
        ) : (
          <ListUsers
            users={users}
            onClickResetPassword={resetPassword}
            onClickDeleteUser={deleteUser}
            onClickManagePermissions={managePermissions}
          />
        )
      }
      <Modal
        width="70%"
        visible={displayModal}
        onCancel={() => closeModal()}
        footer={(
          <ModalFooter
            buttons={[
              { text: 'Cancelar', onClick: () => closeModal(), disabled: loading },
              { text: 'Aceptar', onClick: () => (isCreatingUser ? createUser() : updateUsersPermissions()), disabled: loading },
            ]}
          />
        )}
      >
        <div className="UsersModal">
          {
            isCreatingUser ? (
              <>
                <SelectWithLabel
                  label="Cliente"
                  id="client"
                  options={clientOptions}
                  onChange={clientSelectOnChange}
                  defaultValue={clientOptions[selectedClient] ? clientOptions[selectedClient].value : ''}
                />
                <InputWithLabel
                  label={`Introduzca el nombre de usuario que quieres crear para ${clientOptions[selectedClient] ? clientOptions[selectedClient].label : ''}`}
                  onChange={setNewUsername}
                  value=""
                  id="username"
                />
              </>
            ) : (<></>)
          }
          <Permissions />
        </div>
      </Modal>
      <Pager
        filterValues={filterValues}
        totalItems={totalItems}
        setFilterValues={setFilterValues}
      />
    </div>
  );
};

Users.propTypes = {
  setIsLogged: PropTypes.func.isRequired,
  profile: PropTypes.number.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
};

export default Users;
