import React, { useEffect, useState } from 'react';
import './clientInfo.css';
import { CaretRightOutlined } from '@ant-design/icons';
import Collapse from 'antd/lib/collapse';
import PropTypes from 'prop-types';
import notification from '../../../node_modules/antd/lib/notification';
import Api from '../../Api';
import Utils from '../../Utils';
import InputWithLabel from '../InputWithLabel/InputWithLabel';
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel';
import Spin from '../../../node_modules/antd/lib/spin';
import Alert from '../../../node_modules/antd/lib/alert';

const { Panel } = Collapse;
const { get } = Api;
const { buildHeaders, getUrl } = Utils;

const ClientInfo = () => {
  const [loading, setLoading] = useState(false);
  const [clientInfo, setClientInfo] = useState({});
  const [adressOptions, setAdressOptions] = useState([]);
  const [adress, setAdress] = useState({
    clientAdress: '', poblation: '', province: '', postalCode: '',
  });
  const [fiscalAdress, setFiscalAdress] = useState({
    clientAdress: '', poblation: '', province: '', postalCode: '',
  });
  const [adressSelected, setAdressSelected] = useState(-1);

  const {
    NomClient = '',
    RzsClient = '',
    NifClient = '',
    PerContClient = '',
    Tel1Client = '',
    EmailClient = '',
  } = clientInfo;
  useEffect(() => {
    (
      async () => {
        setLoading(true);
        const generalClientInfoResponse = await get('getclientgeneralinfo', buildHeaders(), getUrl());
        const {
          error, mensaje, clientInfo,
        } = generalClientInfoResponse;
        if (error) {
          notification.error({
            message: 'Error!',
            description: mensaje,
          });
        } else {
          setAdressOptions(clientInfo.adresses.map((adress, i) => (
            {
              label: `${adress.DirClient}, ${adress.PobClient}, ${adress.ProvClient} (${adress.CpClient})`,
              value: i,
            }
          )));
          setClientInfo(clientInfo);
          setAdressSelected(0);
          const {
            DirClient,
            PobClient,
            ProvClient,
            CpClient,
          } = clientInfo;
          setFiscalAdress({
            clientAdress: DirClient, poblation: PobClient, province: ProvClient, postalCode: CpClient,
          });
        }
        setLoading(false);
      }
    )();
  }, []);

  useEffect(() => {
    if (clientInfo.adresses && clientInfo.adresses.length > 0 && adressSelected !== -1) {
      const {
        DirClient,
        PobClient,
        ProvClient,
        CpClient,
      } = clientInfo.adresses[adressSelected];
      setAdress({
        clientAdress: DirClient, poblation: PobClient, province: ProvClient, postalCode: CpClient,
      });
    }
  }, [adressSelected]);

  const AdresssInfo = (props) => {
    const { adress } = props;
    const {
      clientAdress, poblation, province, postalCode,
    } = adress;
    return (
      <div>
        <InputWithLabel
          value={clientAdress}
          disabled
          onChange={() => (null)}
          label="Dirección"
          id="Dirección"
        />
        <InputWithLabel
          value={poblation}
          disabled
          onChange={() => (null)}
          label="Población"
          id="Población"
        />
        <InputWithLabel
          value={province}
          disabled
          onChange={() => (null)}
          label="Provincia"
          id="Provincia"
        />
        <InputWithLabel
          value={postalCode}
          disabled
          onChange={() => (null)}
          label="Código postal"
          id="Código postal"
        />
      </div>
    );
  };

  AdresssInfo.propTypes = {
    adress: PropTypes.shape({
      clientAdress: PropTypes.string.isRequired,
      poblation: PropTypes.string.isRequired,
      province: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
    }).isRequired,
  };

  return (
    <div className="ClientInfo">
      <div className="Container">
        {
          loading ? (
            <Spin tip="Cargando...">
              <Alert
                message="Cargando"
                description="Estamos obteniendo la información del servidor..."
                type="info"
              />
            </Spin>
          ) : (
            <>
              <div className="Header">
                <div>
                  {NomClient}
                  /
                  {RzsClient}
                </div>
                <div>
                  {NifClient}
                </div>
              </div>
              <Collapse
                bordered={false}
                defaultActiveKey={[]}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                <Panel header="Dirección fiscal" key="1">
                  <AdresssInfo
                    adress={fiscalAdress}
                  />
                </Panel>
                <Panel header="Información de contacto" key="2">
                  <div>
                    <InputWithLabel
                      value={PerContClient}
                      disabled
                      onChange={() => (null)}
                      label="Contacto"
                      id="Contacto"
                    />
                    <InputWithLabel
                      value={Tel1Client}
                      disabled
                      onChange={() => (null)}
                      label="Teléfono"
                      id="Teléfono"
                    />
                    <InputWithLabel
                      value={EmailClient}
                      disabled
                      onChange={() => (null)}
                      label="Email"
                      id="Email"
                    />
                  </div>
                </Panel>
                <Panel header="Direcciones de entrega" key="3">
                  <div className="AdressesSelect">
                    <SelectWithLabel
                      label="Direcciones"
                      id="SelectDirecciones"
                      defaultValue={adressSelected}
                      onChange={(e) => setAdressSelected(e)}
                      options={adressOptions}
                      color="rgba(0, 0, 0, 0.65)"
                    />
                  </div>
                  {
                    clientInfo.adresses && clientInfo.adresses.length > 0 ? (
                      <AdresssInfo
                        adress={adress}
                      />
                    ) : (<></>)
                  }
                </Panel>
              </Collapse>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ClientInfo;
