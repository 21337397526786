import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Charges from '../../components/Charges/Charges';
import Contracts from '../../components/Contracts/Contracts';
import ConsumedArticles from '../../components/ConsumedArticles/ConsumedArticles';
import './clientFile.css';
import ClientInfo from '../../components/ClientInfo/ClientInfo';
import Search from '../Search/Search';

const ClientFile = (props) => {
  const {
    setIsLogged, profile, thirdColor, fourthColor, fifthColor, orderLines, setOrderLines,
  } = props;
  const [page, setPage] = useState('contracts');
  const [mouseIsOverGeneralInfo, setMouseIsOverGeneralInfo] = useState(false);
  const [mouseIsOverCharges, setMouseIsOverCharges] = useState(false);
  const [mouseIsOverDocuments, setMouseIsOverDocuments] = useState(false);
  const [mouseIsOverConsumedArticles, setMouseIsOverConsumedArticles] = useState(false);
  const [mouseIsOverContracts, setMouseIsOverContracts] = useState(false);

  const pages = {
    generalInfo: <ClientInfo />,
    charges: <Charges
      thirdColor={thirdColor}
      fourthColor={fourthColor}
      fifthColor={fifthColor}
    />,
    consumedArticles: <ConsumedArticles
      thirdColor={thirdColor}
      fourthColor={fourthColor}
      fifthColor={fifthColor}
      orderLines={orderLines}
      setOrderLines={setOrderLines}
    />,
    contracts: <Contracts />,
    documents: <Search
      profile={profile}
      setIsLogged={setIsLogged}
      thirdColor={thirdColor}
      fourthColor={fourthColor}
      orderLines={orderLines}
      setOrderLines={setOrderLines}
    />,
  };

  return (
    <div className="ClientFile">
      <aside style={{ backgroundColor: thirdColor }}>
        <div
          onBlur={() => setMouseIsOverGeneralInfo(false)}
          onFocus={() => setMouseIsOverGeneralInfo(true)}
          onMouseOut={() => setMouseIsOverGeneralInfo(false)}
          onMouseOver={() => setMouseIsOverGeneralInfo(true)}
          style={{ color: fourthColor, backgroundColor: `${page === 'generalInfo' || mouseIsOverGeneralInfo ? fifthColor : thirdColor}` }}
          className={`${page === 'generalInfo' ? 'Selected' : ''}`}
          onClick={(e) => setPage('generalInfo')}
        >
          Información general
        </div>
        <div
          onBlur={() => setMouseIsOverCharges(false)}
          onFocus={() => setMouseIsOverCharges(true)}
          onMouseOut={() => setMouseIsOverCharges(false)}
          onMouseOver={() => setMouseIsOverCharges(true)}
          style={{ color: fourthColor, backgroundColor: `${page === 'charges' || mouseIsOverCharges ? fifthColor : thirdColor}` }}
          className={`${page === 'charges' ? 'Selected' : ''}`}
          onClick={(e) => setPage('charges')}
        >
          Historial de cobros
        </div>
        <div
          onBlur={() => setMouseIsOverDocuments(false)}
          onFocus={() => setMouseIsOverDocuments(true)}
          onMouseOut={() => setMouseIsOverDocuments(false)}
          onMouseOver={() => setMouseIsOverDocuments(true)}
          style={{ color: fourthColor, backgroundColor: `${page === 'documents' || mouseIsOverDocuments ? fifthColor : thirdColor}` }}
          className={`${page === 'documents' ? 'Selected' : ''}`}
          onClick={(e) => setPage('documents')}
        >
          Historial de documentos
        </div>
        <div
          onBlur={() => setMouseIsOverConsumedArticles(false)}
          onFocus={() => setMouseIsOverConsumedArticles(true)}
          onMouseOut={() => setMouseIsOverConsumedArticles(false)}
          onMouseOver={() => setMouseIsOverConsumedArticles(true)}
          style={{ color: fourthColor, backgroundColor: `${page === 'consumedArticles' || mouseIsOverConsumedArticles ? fifthColor : thirdColor}` }}
          className={`${page === 'consumedArticles' ? 'Selected' : ''}`}
          onClick={(e) => setPage('consumedArticles')}
        >
          Artículos consumidos
        </div>
        <div
          onBlur={() => setMouseIsOverContracts(false)}
          onFocus={() => setMouseIsOverContracts(true)}
          onMouseOut={() => setMouseIsOverContracts(false)}
          onMouseOver={() => setMouseIsOverContracts(true)}
          style={{ color: fourthColor, backgroundColor: `${page === 'contracts' || mouseIsOverContracts ? fifthColor : thirdColor}` }}
          className={`${page === 'contracts' ? 'Selected' : ''}`}
          onClick={(e) => setPage('contracts')}
        >
          Contratos
        </div>
      </aside>
      <div className={`${page === 'documents' ? '' : 'Page'}`}>
        {
          pages[page]
        }
      </div>
    </div>
  );
};

ClientFile.propTypes = {
  setIsLogged: PropTypes.func.isRequired,
  profile: PropTypes.number.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
  orderLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOrderLines: PropTypes.func.isRequired,
};

export default ClientFile;
