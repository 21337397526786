import React from 'react';
import './loadingPage.css';

const LoadingPage = () => (
  <div className="LoadingPage">
    <div className="Loader" />
  </div>
);

export default LoadingPage;
