import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';

const TextAreaWithLabel = (props) => {
  const {
    defaultValue, id, placeholder, reference, label, onPaste,
  } = props;
  // console.log(props);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>{label}</label>
      <TextArea
        defaultValue={defaultValue}
        id={id}
        placeholder={placeholder}
        ref={reference}
        onPaste={(e) => onPaste(e)}
      />
    </>
  );
};

TextAreaWithLabel.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  reference: PropTypes.any.isRequired,
  label: PropTypes.string,
  onPaste: PropTypes.func,
};

TextAreaWithLabel.defaultProps = {
  label: '',
  onPaste: () => (null),
};

export default TextAreaWithLabel;
