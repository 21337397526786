/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import notification from '../../../node_modules/antd/lib/notification';
import Api from '../../Api';
import './clients.css';
import Utils from '../../Utils';
import Button from '../../../node_modules/antd/lib/button';
import SelectWithLabel from '../../components/SelectWithLabel/SelectWithLabel';
import ClientsForm from './ClientsForm';

const {
  buildHeaders, deleteCookies,
} = Utils;
const { get, post } = Api;

const Clients = (props) => {
  const { setIsLogged, profile } = props;

  const emptyClient = {
    IDCliWeb: '',
    IDEmpWEb: '',
    IDCliErp: '',
    Nombre: '',
  };

  const history = useHistory();
  const [displayModal, setDisplayModal] = useState(false);
  const [newButtonType, setNewButtonType] = useState('default');
  const [modifyButtonType, setModifyButtonType] = useState('default');
  const [deleteButtonType, setDeleteButtonType] = useState('default');
  const [cancelButtonType, setCancelButtonType] = useState('default');
  const [saveButtonType, setSaveButtonType] = useState('default');
  const [newButtonDisabled, setNewButtonDisabled] = useState(false);
  const [modifyButtonDisabled, setModifyButtonDisabled] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(emptyClient);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(0);
  const [modifiedClient, setModifiedClient] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [canModifyFields, setCanModifyFields] = useState(false);

  useEffect(() => {
    (async () => {
      const clientsCallResponse = await get('getclients',
        buildHeaders());
      const { error, clients } = clientsCallResponse;
      if (error) {
        setIsLogged(false);
        deleteCookies();
        history.push('/login');
        return;
      }
      setClients(clients);
      setClient(clients[selectedClient]);
      setHasLoaded(true);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (clients.length === 0) {
      return;
    }
    setClientsOptions(clients.map((client) => ({
      label: client.Nombre, value: client.IDCliWeb,
    })));
    setClient(clients[selectedClient]);
  }, [clients, selectedClient]);

  useEffect(() => {
    setHasLoaded(true);
  }, [client]);

  const clientSelectOnChange = (option) => {
    let index = -1;
    clients.forEach((client, i) => {
      if (client.IDCliWeb === option) {
        index = i;
      }
    });
    setSelectedClient(index);
    setHasLoaded(false);
  };

  const closeModal = () => {
    setDisplayModal(false);
  };
  const openModal = () => {
    setDisplayModal(true);
  };

  const removeClient = async () => {
    const idToRemove = clients[selectedClient].IDCliWeb;
    const deleteClientResponse = await post('deleteclient', { IDCliWeb: idToRemove },
      buildHeaders());
    const { error } = deleteClientResponse;
    if (!error) {
      const newClients = clients.filter((client) => client.IDCliWeb !== idToRemove);
      if (newClients.length === selectedClient) {
        setSelectedClient(selectedClient - 1);
      }
      setClients(newClients);
      notification.success({
        message: 'Success!',
        description:
      `El cliente con id ${idToRemove} ha sido eliminada con éxito`,
      });
    } else {
      notification.error({
        message: 'Error',
        description:
      'Algo ha ido mal',
      });
    }
    closeModal();
  };

  const onClickNewButton = () => {
    setNewButtonType('primary');
    setModifyButtonType('default');
    setDeleteButtonType('default');
    setCancelButtonType('default');
    setSaveButtonType('default');
    setModifyButtonDisabled(true);
    setDeleteButtonDisabled(true);
    setCancelButtonDisabled(false);
    setSaveButtonDisabled(false);
    setClient(emptyClient);
    setModifiedClient(emptyClient);
    setCanModifyFields(true);
    setHasLoaded(false);
  };
  const onClickModifyButton = () => {
    setNewButtonType('default');
    setModifyButtonType('primary');
    setDeleteButtonType('default');
    setCancelButtonType('default');
    setSaveButtonType('default');
    setNewButtonDisabled(true);
    setDeleteButtonDisabled(true);
    setCancelButtonDisabled(false);
    setSaveButtonDisabled(false);
    setCanModifyFields(true);
  };
  const onClickDeleteButton = () => {
    openModal();
  };
  const onClickCancelButton = () => {
    setNewButtonType('default');
    setModifyButtonType('default');
    setDeleteButtonType('default');
    setCancelButtonType('primary');
    setSaveButtonType('default');
    setNewButtonDisabled(false);
    setModifyButtonDisabled(false);
    setDeleteButtonDisabled(false);
    setCancelButtonDisabled(true);
    setSaveButtonDisabled(true);
    setCanModifyFields(false);
    setClient(clients[selectedClient]);
  };
  const onClickSaveButton = () => {
    setNewButtonType('default');
    setModifyButtonType('default');
    setDeleteButtonType('default');
    setCancelButtonType('primary');
    setSaveButtonType('default');
    setNewButtonDisabled(false);
    setModifyButtonDisabled(false);
    setDeleteButtonDisabled(false);
    setCancelButtonDisabled(true);
    setSaveButtonDisabled(true);
    setCanModifyFields(false);
    (async () => {
      const createClientResponse = await post('createclient', modifiedClient,
        buildHeaders());
      const { error } = createClientResponse;
      if (error) {
        return;
      }
      setHasLoaded(true);
    })();
  };

  return (
    <div className="Clients">
      {
        profile !== 2 ? (
          <div className="Buttons">
            {profile === 0 ? (
              <Button
                className={profile === 1 ? 'No-Display' : ''}
                type={newButtonType}
                disabled={newButtonDisabled}
                onClick={onClickNewButton}
              >
                Nuevo
              </Button>
            ) : (<></>)}

            <Button
              className={profile === 1 ? 'No-Display' : ''}
              type={modifyButtonType}
              disabled={modifyButtonDisabled}
              onClick={onClickModifyButton}
            >
              Editar
            </Button>
            {profile === 0 ? (
              <Button
                type={deleteButtonType}
                disabled={deleteButtonDisabled}
                onClick={onClickDeleteButton}
              >
                Eliminar
              </Button>
            ) : (<></>)}

            <Button
              type={saveButtonType}
              disabled={saveButtonDisabled}
              onClick={onClickSaveButton}
            >
              Guardar
            </Button>
            <Button
              type={cancelButtonType}
              disabled={cancelButtonDisabled}
              onClick={onClickCancelButton}
            >
              Cancelar
            </Button>
          </div>
        ) : (<></>)
      }
      {
        hasLoaded && clientsOptions.length > 1 && newButtonType !== 'primary'
          ? (
            <SelectWithLabel
              label="Clients"
              id="Clients"
              disabled={newButtonDisabled || modifyButtonDisabled || deleteButtonDisabled}
              options={clientsOptions}
              defaultValue={clientsOptions[selectedClient].value}
              onChange={clientSelectOnChange}
            />
          ) : (<></>)
      }
      <div className="Container">
        <ClientsForm
          client={client}
          setModifiedClient={setModifiedClient}
          key={client.IDCliWeb || 'new'}
          readOnly={!canModifyFields}
        />
      </div>
      <div className={`Modal ${displayModal ? '' : 'No-Display'}`}>
        <div className="Content">
          <div className="Info">
            {client ? `Te gustaría eliminar al cliente ${client.Nombre}?` : ''}
          </div>
          <div className="Options">
            <Button
              onClick={removeClient}
            >
              Sí
            </Button>
            <Button onClick={closeModal}>No</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Clients.propTypes = {
  setIsLogged: PropTypes.func.isRequired,
  profile: PropTypes.number.isRequired,
};

export default Clients;
