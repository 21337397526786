import React, { useState, useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Collapse from '../../../node_modules/antd/lib/collapse';
import './imports.css';
import Utils from '../../Utils';

const { formatDecimals, applyDiscount } = Utils;

const { Panel } = Collapse;

const Imports = (props) => {
  const { orderLines, clientInfo } = props;
  const {
    DcoComClient, DcoPpClient, PORCERET, RECSN,
  } = clientInfo;

  const [grossTotal, setGrossTotal] = useState(0);
  const [taxes, setTaxes] = useState({});
  const TotalField = ({ field, total }) => (
    <div className={`TotalField ${field === 'Total' ? 'Total' : ''}`}>
      <div>{field}</div>
      <div>{total}</div>
    </div>
  );

  TotalField.propTypes = {
    field: PropTypes.string.isRequired,
    total: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  };

  useEffect(() => {
    setGrossTotal(orderLines.reduce((prev, current) => prev + current.price, 0));
  }, [orderLines]);

  useEffect(() => {
    const newTaxes = orderLines.reduce((prev, current) => {
      prev[current.taxClass] = {
        ...prev[current.taxClass],
        taxBase: prev[current.taxClass]
          ? prev[current.taxClass].taxBase + current.price : current.price,
        taxPercent: current.taxPercent,
        taxSurchargePercent: current.taxSurchargePercent,
      };
      return prev;
    }, {});
    setTaxes(newTaxes);
  }, [grossTotal]);

  const InformationDiscounts = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '30%', textAlign: 'center' }}>Total Bruto</div>
        <div style={{ width: '30%', textAlign: 'center' }}>Descuento Comercial</div>
        <div style={{ width: '30%', textAlign: 'center' }}>Descuento Pronto Pago</div>
      </div>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '30%', textAlign: 'center' }}>
          {
            formatDecimals(grossTotal, 2)
          }
        </div>
        <div style={{ width: '30%', textAlign: 'center' }}>
          {DcoComClient}
          % (
          {formatDecimals(grossTotal * (DcoComClient / 100), 2)}
          )
        </div>
        <div style={{ width: '30%', textAlign: 'center' }}>
          {DcoPpClient}
          % (
          {formatDecimals(grossTotal * (DcoPpClient / 100), 2)}
          )
        </div>
      </div>
    </>
  );
  const InformationTaxes = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '30%', textAlign: 'center' }}>Base imponible</div>
        <div style={{ width: '30%', textAlign: 'center' }}>IVA</div>
        <div style={{ width: '30%', textAlign: 'center' }}>Recargo</div>
      </div>
      <hr />
      {
        Object.keys(taxes).map((tax) => (
          <div key={tax} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '30%', textAlign: 'center' }}>
              {formatDecimals(applyDiscount(applyDiscount(
                taxes[tax].taxBase, DcoComClient,
              ), DcoPpClient), 2)}
            </div>
            <div style={{ width: '30%', textAlign: 'center' }}>
              {taxes[tax].taxPercent}
              {' '}
              (
              {formatDecimals(applyDiscount(applyDiscount(
                taxes[tax].taxBase, DcoComClient,
              ), DcoPpClient)
              * ((taxes[tax].taxPercent / 100)), 2)}
              )
            </div>
            <div style={{ width: '30%', textAlign: 'center' }}>
              {taxes[tax].taxSurchargePercent}
              {' '}
              (
              {RECSN === 'N' ? 0 : formatDecimals(applyDiscount(applyDiscount(taxes[tax].taxBase, DcoComClient),
                DcoPpClient)
                  * ((taxes[tax].taxSurchargePercent / 100)), 2)}
              )
            </div>
          </div>
        ))
      }
    </>
  );
  return (
    <fieldset>
      <legend>IMPORTES</legend>
      <Collapse
        bordered={false}
        defaultActiveKey={[]}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
      >
        <Panel header="Bruto y descuentos" key="1" className="site-collapse-custom-panel">
          <InformationDiscounts />
        </Panel>
        <Panel header="Desglose de ivas" key="2" className="site-collapse-custom-panel">
          <InformationTaxes />
        </Panel>
      </Collapse>
      <TotalField
        field="Base imponible"
        total={formatDecimals(Object.keys(taxes).reduce(
          (prev, current) => prev
          + applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient), 0,
        ), 2)}
      />
      <TotalField
        field="Importe de IVA"
        total={formatDecimals(Object.keys(taxes).reduce(
          (prev, current) => prev
          + (applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient)
            * ((taxes[current].taxPercent / 100))), 0,
        ), 2)}
      />
      <TotalField
        field="Importe de REC"
        total={RECSN === 'N' ? 0 : formatDecimals(Object.keys(taxes).reduce(
          (prev, current) => prev
          + (applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient)
            * ((taxes[current].taxSurchargePercent / 100))), 0,
        ), 2)}
      />
      <TotalField
        field="Retención"
        total={`${PORCERET}% (${formatDecimals(Object.keys(taxes).reduce(
          (prev, current) => prev
          + (applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient)
          * (PORCERET / 100)), 0,
        ), 2)})`}
      />
      <TotalField
        field="Total"
        total={formatDecimals(Object.keys(taxes).reduce(
          (prev, current) => prev
          + applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient)
          + (applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient)
            * ((taxes[current].taxPercent / 100)))
            + (RECSN === 'N' ? 0 : (applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient)
            * ((taxes[current].taxSurchargePercent / 100))))
            - (applyDiscount(applyDiscount(taxes[current].taxBase, DcoComClient), DcoPpClient)
            * (PORCERET / 100)), 0,
        ), 2)}
      />
      <div className="Discounts" />
    </fieldset>
  );
};

Imports.propTypes = {
  orderLines: PropTypes.arrayOf(PropTypes.shape({
    codArtic: PropTypes.string.isRequired,
    combos: PropTypes.arrayOf(PropTypes.object).isRequired,
    description: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    originalPrice: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    taxClass: PropTypes.string.isRequired,
    taxPercent: PropTypes.number.isRequired,
    taxSurchargePercent: PropTypes.number.isRequired,
    unities: PropTypes.number.isRequired,
  })).isRequired,
  clientInfo: PropTypes.shape({
    AGENTE: PropTypes.number.isRequired,
    DcoComClient: PropTypes.number.isRequired,
    DcoPpClient: PropTypes.number.isRequired,
    IvaArtClient: PropTypes.string.isRequired,
    PORCEIVACLIENT: PropTypes.number.isRequired,
    PORCERET: PropTypes.number.isRequired,
    RECSN: PropTypes.string.isRequired,
    RETSN: PropTypes.string.isRequired,
    Tarclient: PropTypes.string.isRequired,
    formpagclient: PropTypes.number.isRequired,
  }).isRequired,
};

export default Imports;
