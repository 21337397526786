const Utils = {
  getCookies() {
    if (!document.cookie) {
      return {};
    }
    const documentCookies = document.cookie.split('cookies=').pop();
    try {
      const documentCookiesJson = JSON.parse(documentCookies);
      return documentCookiesJson;
    } catch (e) {
      return {};
    }
  },
  saveCookies(data) {
    document.cookie = `cookies=${JSON.stringify(data)}`;
  },
  deleteCookies() {
    const cookies = Utils.getCookies();
    const { companyToken } = cookies;
    document.cookie = `cookies=${JSON.stringify({ companyToken })}`;
  },
  buildHeaders() {
    const { user, token } = Utils.getCookies();
    return { usuario: user, token };
  },
  getUrl() {
    return Utils.getCookies().url;
  },
  generateParams(params) {
    const getCorrelationValue = (key, value) => {
      const correlationValues = {
        cliente: `cliente=${value}`,
        ideje: `ideje=${value}`,
        serfac: `serfac=${value}`,
        tipodoc: `tipodoc=${value}`,
        pagina: `pagina=${value}`,
        numero: `numero=${value}`,
        url: `url=${value}`,
        filtro: `filtro=${value}`,
        client: `client=${value}`,
        user: `user=${value}`,
        companyToken: `companyToken=${value}`,
        family: `family=${value}`,
        dates: `dates=${value}`,
        paid: `paid=${value}`,
        articleType: `articleType=${value}`,
      };

      return correlationValues[key];
    };

    const urlParams = params.map((param) => {
      const { key, value } = param;
      return getCorrelationValue(key, value);
    });
    return urlParams.join('&');
  },
  convertHexadecimalToFile(hexadecimal) {
    const cleanedHexadecimal = hexadecimal.toUpperCase().replace(/0x/gi, '');

    const binary = [];
    for (let i = 0; i < cleanedHexadecimal.length / 2; i += 1) {
      const h = cleanedHexadecimal.substr(i * 2, 2);
      binary[i] = parseInt(h, 16);
    }
    const byteArray = new Uint8Array(binary);
    return new window.Blob([byteArray], { type: 'application/pdf' });
  },
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  formatDecimals(number, decimals) {
    if ((number * (10 ** (decimals + 1))) % 5 === 0) {
      return parseFloat(number + (1 / (10 ** (decimals + 1)))).toFixed(decimals);
    }
    return (parseFloat(number).toFixed(decimals));
  },
  applyDiscount(number, discount) {
    return number - (number * (discount / 100));
  },
  getNumbersFromRGB(rgb) {
    try {
      return rgb.slice(0, -1).slice(4);
    } catch (e) {
      return '0,0,0';
    }
  },
  rgbToHex(rgb) {
    const formattedRGB = Utils.getNumbersFromRGB(rgb);
    try {
      return `#${formattedRGB.split(',').map((x) => {
        const hex = (+x).toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      }).join('')}`;
    } catch (e) {
      return '#000000';
    }
  },
  hexToRgb(hex) {
    try {
      return `rgb(${hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
        .substring(1).match(/.{2}/g)
        .map((x) => parseInt(x, 16))
        .join(',')})`;
    } catch (e) {
      return 'rgb(0,0,0)';
    }
  },
};

export default Utils;
