import React from 'react';
import PropTypes from 'prop-types';
import './filter.css';
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel';

const Filter = (props) => {
  const {
    serfacOptions,
    docTypeOptions,
    idejeOptions,
    clientOptions,
    filterValues,
    setFilterValues,
    profile,
    thirdColor,
    fourthColor,
  } = props;
  const {
    serfac, docType, ideje, client,
  } = filterValues;

  const onChangeSerfac = (value) => {
    setFilterValues({ ...filterValues, serfac: value, page: 1 });
  };
  const onChangeDocType = (value) => {
    setFilterValues({ ...filterValues, docType: value, page: 1 });
  };
  const onChangeIdeje = (value) => {
    setFilterValues({ ...filterValues, ideje: value, page: 1 });
  };
  const onChangeClient = (value) => {
    setFilterValues({ ...filterValues, client: value, page: 1 });
  };

  return (
    <div className="Filter" style={{ backgroundColor: thirdColor }}>
      {
        profile === 1 ? (
          <div className="Item">
            <SelectWithLabel
              color={fourthColor}
              label="Cliente"
              id="client"
              options={clientOptions}
              onChange={onChangeClient}
              defaultValue={client}
            />
          </div>
        ) : (<></>)
      }
      <div className="Item">
        <SelectWithLabel
          color={fourthColor}
          label="Serie de facturación"
          id="serfac"
          options={serfacOptions}
          onChange={onChangeSerfac}
          defaultValue={serfac}
        />
      </div>
      <div className="Item">
        <SelectWithLabel
          color={fourthColor}
          label="Documento"
          id="docType"
          options={docTypeOptions}
          onChange={onChangeDocType}
          defaultValue={docType}
        />
      </div>
      <div className="Item">
        <SelectWithLabel
          color={fourthColor}
          label="Ejercicio"
          id="ideje"
          options={idejeOptions}
          onChange={onChangeIdeje}
          defaultValue={ideje}
        />
      </div>
    </div>
  );
};

Filter.propTypes = {
  setFilterValues: PropTypes.func.isRequired,
  filterValues: PropTypes.shape({
    serfac: PropTypes.string.isRequired,
    docType: PropTypes.string.isRequired,
    ideje: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    client: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    page: PropTypes.number.isRequired,
    elementsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  serfacOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.PropTypes.string.isRequired,
  })).isRequired,
  docTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.PropTypes.string.isRequired,
  })).isRequired,
  idejeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  clientOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  profile: PropTypes.number.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
};

export default Filter;
