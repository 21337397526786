import React, { useEffect, useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { LoadingOutlined } from '@ant-design/icons';
import notification from '../../../node_modules/antd/lib/notification';
import Api from '../../Api';
import Utils from '../../Utils';
import Contract from './Contract';
import Modal from '../../../node_modules/antd/lib/modal';
import ModalFooter from '../ModalFooter/ModalFooter';
import Spin from '../../../node_modules/antd/lib/spin';
import Alert from '../../../node_modules/antd/lib/alert';
import Input from '../../../node_modules/antd/lib/input';
import './contracts.css';


const { get, post } = Api;
const { buildHeaders, getUrl, convertHexadecimalToFile } = Utils;
const antIcon = <LoadingOutlined style={{ fontSize: 104 }} spin />;

const Contracts = () => {
  const [contracts, setContracts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSignModalVisible, setIsSignModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [namePdf, setNamePdf] = useState('');
  const [contractType, setContractType] = useState(-1);
  const [contractStatus, setContractStatus] = useState('');
  const [pdfOpened, setPdfOpened] = useState('');
  const uploadRef = useRef(null);
  const canvasRef = useRef(null);
  const dniRef = useRef(null);

  const getContracts = async () => {
    setLoading(true);
    const contractsResponse = await get('getcontracts', buildHeaders(), getUrl());
    const { contracts, mensaje, error } = contractsResponse;
    if (error) {
      notification.error({
        message: 'Error!',
        description: mensaje,
      });
    } else {
      setContracts(contracts);
    }
    setLoading(false);
  };

  const downloadPdf = (pdf, name) => {
    const buffer = Buffer.from(pdf, 'base64');
    const bufString = buffer.toString('hex');
    const hexadecimalPdf = convertHexadecimalToFile(bufString);
    const data = window.URL.createObjectURL(hexadecimalPdf);
    const link = document.createElement('a');
    link.href = data;
    link.download = `${name.replace(/ /g, '')}.pdf`;
    link.click();
  };

  const uploadSignedContract = (pdf) => {
    (async () => {
      setLoading(true);
      const body = {
        contractType,
        document: pdf,
      };
      const updateSignedContractResponse = await post('uploadsignedcontract', body, buildHeaders(), getUrl());
      const { error, mensaje } = updateSignedContractResponse;
      if (error) {
        notification.error({
          message: 'Error!',
          description: mensaje,
        });
        setLoading(false);
      } else {
        notification.success({
          message: 'Success!',
          description: mensaje,
        });
        setIsModalVisible(false);
        getContracts();
      }
    })();
  };

  useEffect(() => {
    getContracts();
  }, []);

  const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file.type !== 'application/pdf') {
      notification.error({
        message: 'Error!',
        description: 'Solo se pueden subir archivos en formato pdf',
      });
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => resolve(uploadSignedContract(reader.result));
    reader.onerror = (error) => reject(error);
  });

  const cancelModal = () => {
    if (!loading) {
      setIsModalVisible(false);
    }
  };

  const openModal = (pdf, name, contractType, status) => {
    setContractStatus(status);
    setContractType(contractType);
    setNamePdf(name);
    setPdfOpened(pdf);
    setIsModalVisible(true);
  };

  const signContract = () => {
    if (canvasRef.current.isEmpty()) {
      notification.error({
        message: 'Error!',
        description: 'La firma está vacía y es un campo obligatorio.',
      });
      return;
    }
    if (dniRef.current.state.value === '') {
      notification.error({
        message: 'Error!',
        description: 'El campo del DNI/CIF no puede estar vacío.',
      });
      return;
    }
    const sign = canvasRef.current.toDataURL();
    (async () => {
      setLoading(true);
      const body = {
        contractType,
        sign,
        dni: dniRef.current.state.value,
      };
      const updateSignResponse = await post('uploadSign', body, buildHeaders(), getUrl());
      const { error, mensaje } = updateSignResponse;
      if (error) {
        notification.error({
          message: 'Error!',
          description: mensaje,
        });
        setLoading(false);
      } else {
        notification.success({
          message: 'Success!',
          description: mensaje,
        });
        setIsSignModalVisible(false);
        setIsModalVisible(false);
        getContracts();
      }
    })();
  };

  const modalButtons = [
    { text: 'Descargar archivo', onClick: () => downloadPdf(pdfOpened, namePdf), disabled: loading },
  ];

  if (contractStatus !== 'X') {
    modalButtons.push({ text: 'Subir archivo firmado', onClick: () => (uploadRef.current.click()), disabled: loading });
    modalButtons.push({ text: 'Firmar archivo', onClick: () => (setIsSignModalVisible(true)), disabled: loading });
  }

  return (
    <div className="Contracts">
      <div className="Container">
        {
            loading ? (
              <Spin tip="Cargando...">
                <Alert
                  message="Cargando"
                  description="Estamos obteniendo la información del servidor..."
                  type="info"
                />
              </Spin>
            ) : (
              contracts.map((contract) => (
                <Contract
                  key={contract.TipoContrato}
                  contract={contract}
                  openModal={openModal}
                />
              ))
            )
        }
      </div>
      <Modal
        width="70%"
        height="100%"
        className="ContractModal"
        visible={isModalVisible}
        onCancel={() => cancelModal()}
        footer={(
          <ModalFooter
            buttons={modalButtons}
          />
        )}
      >
        <input type="file" hidden ref={uploadRef} onChange={(e) => fileToBase64(e.target.files[0])} />
        {
         loading ? (<Spin indicator={antIcon} />) : (
           <iframe
             title={`PDF del contrato ${namePdf}`}
             width="100%"
             height="100%"
             src={contracts.length > 0 ? `data:application/pdf;base64,${pdfOpened}` : ''}
           />
         )
       }
      </Modal>
      <Modal
        width="70%"
        height="100%"
        visible={isSignModalVisible}
        onCancel={() => setIsSignModalVisible(false)}
        footer={(
          <ModalFooter
            buttons={[
              { text: 'Limpiar firma', onClick: () => (canvasRef.current.clear()), disabled: loading },
              { text: 'Firmar', onClick: () => signContract(), disabled: loading },
            ]}
          />
        )}
      >
        <div className="ContractSign">
          <div>
            Firma en el siguiente recuadro e introduzca su DNI para otorgar validez al contrato.
          </div>
          <label>DNI</label>
          <Input
            ref={dniRef}
            placeholder="Intrudzca el DNI del representante de la empresa"
          />
          <label>Firma</label>
          <SignatureCanvas
            ref={canvasRef}
            penColor="black"
            canvasProps={{ className: 'sigCanvas' }}
          />
          <div>
            La firma será revisada por IT&P para comprobar su validez y se le mandará
            una copia del contrato firmado por correo electrónico.
          </div>
        </div>
      </Modal>
    </div>


  );
};

export default Contracts;
