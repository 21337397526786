import React, { useState, useEffect } from 'react';
import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Search from './pages/Search/Search';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Utils from './Utils';
import Api from './Api';
import Companies from './pages/Companies/Companies';
import Clients from './pages/Clients/Clients';
import Users from './pages/Users/Users';
import Profile from './pages/Profile/Profile';
import ClientFile from './pages/ClientFile/ClientFile';
import LoadingPage from './pages/LoadingPage/LoadingPage';

const {
  getCookies, buildHeaders, deleteCookies, generateParams, saveCookies,
} = Utils;
const { get } = Api;
export default function App() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const [profile, setProfile] = useState(1);
  const [hasPassword, setHasPassword] = useState(true);
  const [company, setCompany] = useState({});
  const [homeTitle, setHomeTitle] = useState('');
  const [homeText, setHomeText] = useState('');
  const [firstColor, setFirstColor] = useState('#525192');
  const [secondColor, setSecondColor] = useState('#ffffff');
  const [thirdColor, setThirdColor] = useState('#A5A5CC');
  const [fourthColor, setFourthColor] = useState('#ffffff');
  const [fifthColor, setFifthColor] = useState('rgb(255, 152, 0)');
  const [orderLines, setOrderLines] = useState([]);
  const [logo, setLogo] = useState('https://itandp.es/wp-content/uploads/2019/11/LOGO_PNG_blanco.png');
  const [backgroundHome, setBackgroundHome] = useState('./images/FONDO_HOME.jpg');
  const [backgroundSquareHome, setBackgroundSquareHome] = useState('./images/CUADRO_HOME.jpg');

  useEffect(() => {
    const cookies = getCookies();
    const {
      user, token, profile, hasPassword,
    } = cookies;
    const hasCredentials = user && user !== '' && token && token !== '';
    setIsLogged(hasCredentials || false);
    setProfile(profile === 0 ? profile : profile || 4);
    setHasPassword(hasPassword || false);
  }, []);

  useEffect(() => {
    (async () => {
      const cookies = getCookies();
      const {
        user, companyToken,
      } = cookies;
      const token = window.location.search || companyToken;
      const params = [
        { key: 'user', value: user },
        { key: 'companyToken', value: token },
      ];
      const companyCallResponse = await get(`getcompany?${generateParams(params)}`,
        buildHeaders());
      const { error, company } = companyCallResponse;
      if (error) {
        setIsLogged(false);
        deleteCookies();
        history.push('/login');
      }
      cookies.companyToken = window.location.search || companyToken;
      saveCookies(cookies);
      const {
        Logo,
        Color1,
        Color2,
        Color3,
        Color4,
        Color5,
        // eslint-disable-next-line camelcase
        Texto1_Home,
        // eslint-disable-next-line camelcase
        Texto2_Home,
        // eslint-disable-next-line camelcase
        fondo_home,
        // eslint-disable-next-line camelcase
        fondo_cuadro_home,
      } = company;
      setLogo(Logo);
      setHomeTitle(Texto1_Home);
      setHomeText(Texto2_Home);
      setFirstColor(Color1);
      setSecondColor(Color2);
      setThirdColor(Color3);
      setFourthColor(Color4);
      setFifthColor(Color5);
      setCompany(company);
      setLoading(false);
      setBackgroundHome(fondo_home);
      setBackgroundSquareHome(fondo_cuadro_home);
    })();
    // eslint-disable-next-line
  }, [isLogged]);

  return (
    <Router>
      {
        loading ? (<></>) : (
          <Header
            firstColor={firstColor}
            secondColor={secondColor}
            isLogged={isLogged}
            setIsLogged={setIsLogged}
            profile={profile}
            hasPassword={hasPassword}
            logo={logo}
            setLogo={setLogo}
          />
        )
      }
      <Switch>
        <Route exact path="/">
          {
            loading ? (<LoadingPage />)
              : (
                <Home
                  homeTitle={homeTitle}
                  homeText={homeText}
                  backgroundHome={backgroundHome}
                  backgroundSquareHome={backgroundSquareHome}
                />
              )
          }
        </Route>
        <Route exact path="/profile">
          {
            loading ? (<LoadingPage />) : (
              <Profile
                hasPassword={hasPassword}
                setHasPassword={setHasPassword}
                thirdColor={thirdColor}
                fourthColor={fourthColor}
                fifthColor={fifthColor}
              />
            )
          }
        </Route>
        <Route exact path="/client">
          {
            loading ? (<LoadingPage />) : (
              <ClientFile
                profile={profile}
                setIsLogged={setIsLogged}
                thirdColor={thirdColor}
                fourthColor={fourthColor}
                fifthColor={fifthColor}
                orderLines={orderLines}
                setOrderLines={setOrderLines}
              />
            )
          }
        </Route>
        <Route exact path="/search">
          {
            loading ? (<LoadingPage />) : (
              <Search
                profile={profile}
                setIsLogged={setIsLogged}
                thirdColor={thirdColor}
                fourthColor={fourthColor}
                orderLines={orderLines}
                setOrderLines={setOrderLines}
              />
            )
          }
        </Route>
        <Route exact path="/companies">
          {
            loading ? (<LoadingPage />) : (
              <Companies
                profile={profile}
                setIsLogged={setIsLogged}
                thirdColor={thirdColor}
                fourthColor={fourthColor}
                fifthColor={fifthColor}
                setHomeTitle={setHomeTitle}
                setHomeText={setHomeText}
                setFirstColor={setFirstColor}
                setSecondColor={setSecondColor}
                setThirdColor={setThirdColor}
                setFourthColor={setFourthColor}
                setFifthColor={setFifthColor}
                setLogo={setLogo}
                setBackgroundHome={setBackgroundHome}
                setBackgroundSquareHome={setBackgroundSquareHome}
              />
            )
          }
        </Route>
        <Route exact path="/clients">
          {
            loading ? (<LoadingPage />) : (<Clients setIsLogged={setIsLogged} profile={profile} />)
          }
        </Route>
        <Route exact path="/users">
          {
            loading ? (<LoadingPage />) : (
              <Users
                setIsLogged={setIsLogged}
                profile={profile}
                thirdColor={thirdColor}
                fourthColor={fourthColor}
                fifthColor={fifthColor}
              />
            )
          }
        </Route>
        <Route exact path="/logout">
          {
            loading ? (<LoadingPage />) : (<Logout />)
          }
        </Route>
        <Route path="/login">
          {
            loading ? (<LoadingPage />) : (
              <Login
                setHasPassword={setHasPassword}
                setIsLogged={setIsLogged}
                setProfile={setProfile}
                thirdColor={thirdColor}
                fourthColor={fourthColor}
                fifthColor={fifthColor}
                setFirstColor={setFirstColor}
                setSecondColor={setSecondColor}
                setThirdColor={setThirdColor}
                setFourthColor={setFourthColor}
                setFifthColor={setFifthColor}
              />
            )
          }
        </Route>
      </Switch>
      {
        loading ? (<></>) : (
          <Footer
            firstColor={firstColor}
            secondColor={secondColor}
            company={company}
          />
        )
      }
    </Router>
  );
}
