import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../node_modules/antd/lib/button';
import Spin from '../../../node_modules/antd/lib/spin';
import Api from '../../Api';
import InputWithLabel from '../../components/InputWithLabel/InputWithLabel';
import './login.css';
import Utils from '../../Utils';

const { saveCookies } = Utils;
const { post } = Api;

const Login = (props) => {
  const {
    setIsLogged,
    setProfile,
    setHasPassword,
    thirdColor,
    fourthColor,
    fifthColor,
    setFirstColor,
    setSecondColor,
    setThirdColor,
    setFourthColor,
    setFifthColor,
  } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mouseOver, setMouseOver] = useState(false);
  const history = useHistory();

  async function login() {
    setLoading(true);

    try {
      const response = await post('login', {
        user: username,
        password,
      });
      const {
        mensaje,
        error: isError,
        clientCode,
        token,
        profile,
        companyToken,
        firstColor,
        secondColor,
        thirdColor,
        fourthColor,
        fifthColor,
      } = response;
      setMessage(mensaje);
      if (!isError) {
        saveCookies({
          token, user: username, clientCode, profile, hasPassword: password !== '', companyToken,
        });
        setFirstColor(firstColor);
        setSecondColor(secondColor);
        setThirdColor(thirdColor);
        setFourthColor(fourthColor);
        setFifthColor(fifthColor);
        setIsLogged(true);
        setProfile(profile);
        setHasPassword(password !== '');
        if (profile !== 0) {
          history.push(`${password === '' ? '/profile' : '/search'}`);
        } else {
          history.push('/');
        }
        return;
      }
      setError(isError);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  // const handleRegisterClick = () => history.push('/register');

  const handleLoginClick = () => {
    login();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  return (
    <div className="Login">
      <h2>Inicia sesión</h2>
      {
        error ? <span className="Error">{message}</span> : <></>
      }
      <div className="Box">
        <InputWithLabel
          onChange={setUsername}
          value={username}
          id="username"
          label="Introduzca su usuario"
        />
        <InputWithLabel
          onChange={setPassword}
          value={password}
          id="password"
          label="Introduzca su contraseña"
          type="password"
          onKeyDown={handleKeyDown}
        />
        {/* <Link to="/">Has olvidado tu contraseña? Haz click aquí!</Link> */}
        <Button
          style={{
            backgroundColor: mouseOver ? fifthColor : thirdColor,
            borderColor: mouseOver ? fifthColor : thirdColor,
            fontWeight: 'bold',
            color: fourthColor,
          }}
          onBlur={() => setMouseOver(false)}
          onFocus={() => setMouseOver(true)}
          onMouseOut={() => setMouseOver(false)}
          onMouseOver={() => setMouseOver(true)}
          disabled={loading}
          onClick={handleLoginClick}
          type="default"
        >
          Iniciar sesión
        </Button>
        {/* <Button
          type="primary"
          onClick={handleRegisterClick}
        >
          No tienes una cuenta? Regístrate
        </Button> */}
      </div>
      {
        loading ? (
          <Spin tip="Comprobando credenciales..." />
        ) : <></>
      }
    </div>
  );
};

Login.propTypes = {
  setIsLogged: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  setHasPassword: PropTypes.func.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
  setFirstColor: PropTypes.func.isRequired,
  setSecondColor: PropTypes.func.isRequired,
  setThirdColor: PropTypes.func.isRequired,
  setFourthColor: PropTypes.func.isRequired,
  setFifthColor: PropTypes.func.isRequired,
};

export default Login;
