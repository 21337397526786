import React,
{ useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from '../../../node_modules/antd/lib/popover';
import Input from '../../../node_modules/antd/lib/input';
import Utils from '../../Utils';
import notification from '../../../node_modules/antd/lib/notification';

const { formatDecimals } = Utils;

const OrderLine = (props) => {
  const {
    orderLine, removeLine, updateField, profile, seePrices,
  } = props;
  const {
    codArtic, description, unities, discount, price, images, combos,
  } = orderLine;

  const [onEdit, setOnEdit] = useState(false);
  const unitiesRef = useRef(null);
  const discountRef = useRef(null);

  const updateLine = () => {
    const lineUnities = unitiesRef.current.state.value > 0 ? unitiesRef.current.state.value : 1;
    const newDiscount = discountRef.current ? discountRef.current.state.value : discount;
    const lineDiscount = profile === 1
      && (newDiscount < 0
      || newDiscount > 100)
      ? discount : newDiscount;
    const values = {
      unities: +lineUnities,
      discount: +lineDiscount,
    };
    updateField(values, codArtic);
    setOnEdit(false);
    notification.success({
      message: 'Éxito!',
      description:
      `Se ha actualizado la información de la línea del artículo ${codArtic} con éxito`,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      updateLine();
    }
  };


  return (
    <>
      <div
        className="OrderLine"
      >
        <Popover
          content={(
            <img
              src={images.length > 0 ? `data:image/png;base64, ${images[0].ImagenBase64}` : ''}
              alt="Imagen artículo"
            />
          )}
        >
          <img
            src={images.length > 0 ? `data:image/png;base64, ${images[0].ImagenBase64}` : ''}
            alt="Imagen artículo"
          />
        </Popover>
        <div className="Body">
          <div className="Information">
            <Popover content={codArtic}>
              <div>{codArtic}</div>
            </Popover>
            <Popover content={description}>
              <div className="Description">{description}</div>
            </Popover>
            <div>
              {
                onEdit ? (
                  <>
                    <Input
                      type="number"
                      ref={unitiesRef}
                      defaultValue={unities}
                      placeholder="Unidades"
                      onKeyDown={(e) => handleKeyDown(e, 'unities')}
                    />
                    {' unidades'}
                  </>
                ) : (
                  `${unities} ${unities === 1 ? 'unidad' : 'unidades'}`
                )
              }
            </div>
            {
              seePrices ? (
                <div>
                  {
                onEdit && profile === 1
                  ? (
                    <>
                      <Input
                        type="number"
                        ref={discountRef}
                        defaultValue={discount}
                        placeholder="Descuento"
                        onKeyDown={(e) => handleKeyDown(e, 'discount')}
                      />
                      {' descuento'}
                    </>
                  )
                  : (
                    `Descuento:
                      ${' '}
                      ${discount || '0'}
                      %`
                  )
              }
                </div>
              ) : (<div />)
            }
            {
              seePrices ? (
                <Popover content={price || '0'}>
                  <div>
                    Total:
                    {' '}
                    {formatDecimals(price, 2) || '0'}
                  </div>
                </Popover>
              ) : (<></>)
            }
          </div>
          <div>
            {
              !onEdit ? (
                <div
                  className="Edit"
                  tabIndex={0}
                  role="button"
                  onClick={() => setOnEdit(true)}
                />
              ) : (
                <div
                  className="Update"
                  tabIndex={0}
                  role="button"
                  onClick={() => updateLine()}
                />
              )
            }
            <div
              className="Remove"
              tabIndex={0}
              role="button"
              onClick={() => removeLine(codArtic, description)}
            />
          </div>
        </div>
      </div>
      {
        combos.map((combo) => {
          const {
            ArtDescan,
            DescDescan,
            UniDescan,
            images: imagesCombo,
          } = combo;
          return (
            <div className="OrderLine" key={`${codArtic}${ArtDescan}`}>
              <Popover content={<img src={imagesCombo.length > 0 ? `data:image/png;base64, ${imagesCombo[0].ImagenBase64}` : ''} alt="Imagen artículo" />}>
                <img src={imagesCombo.length > 0 ? `data:image/png;base64, ${imagesCombo[0].ImagenBase64}` : ''} alt="Imagen artículo" />
              </Popover>
              <div className="Body">
                <div className="Information">
                  <Popover content={ArtDescan}>
                    <div>{ArtDescan}</div>
                  </Popover>
                  <Popover content={DescDescan}>
                    <div className="Description">{DescDescan}</div>
                  </Popover>
                  <Popover content={unities}>
                    <div>
                      {UniDescan * unities === 1 ? `${UniDescan * unities} unidad` : `${UniDescan * unities} unidades`}
                    </div>
                  </Popover>
                  {
                    seePrices ? (
                      <>
                        <Popover content={discount || '0'}>
                          <div>
                            Descuento del
                            {' '}
                            0
                            %
                          </div>
                        </Popover>
                        <Popover content="0">
                          <div>
                            Precio de
                            {' '}
                            {formatDecimals(0, 0)}
                          </div>
                        </Popover>
                      </>
                    ) : (<></>)
                  }
                </div>
              </div>
            </div>
          );
        })
      }
    </>
  );
};

OrderLine.propTypes = {
  orderLine: PropTypes.shape({
    codArtic: PropTypes.string.isRequired,
    combos: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(PropTypes.object),
    originalPrice: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    taxClass: PropTypes.string.isRequired,
    taxPercent: PropTypes.number.isRequired,
    taxSurchargePercent: PropTypes.number.isRequired,
    unities: PropTypes.number.isRequired,
  }).isRequired,
  removeLine: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  profile: PropTypes.number.isRequired,
  seePrices: PropTypes.number.isRequired,
};

export default OrderLine;
