import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Popover from '../../../node_modules/antd/lib/popover';
import Button from '../../../node_modules/antd/lib/button';
import Modal from '../../../node_modules/antd/lib/modal';
import Input from '../../../node_modules/antd/lib/input';
import ModalFooter from '../ModalFooter/ModalFooter';

const ConsumedArticle = (props) => {
  const {
    article, orderLines, setOrderLines, thirdColor, fourthColor, fifthColor,
  } = props;
  const {
    CodArtic,
    DescArtic,
    images,
    Cantidad,
    Fecha,
    Descuento,
    ClaseIvas,
    PorceIvaIvas,
    PorceRECIvas,
    PORCEIVACLIENT,
    Tarclient,
    IvaArtClient,
    combos,
  } = article;
  const price = {
    1: 'pvp1artic',
    2: 'pvp2artic',
    3: 'pvp3artic',
    4: 'pvp4artic',
  };

  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [unities, setUnities] = useState(Cantidad);

  const addLine = () => {
    let newOrderLines = [];
    if (orderLines.filter((line) => line.codArtic === CodArtic).length > 0) {
      newOrderLines = orderLines.map((line) => ({
        ...line,
        unities: line.codArtic === CodArtic ? line.unities + unities : line.unities,
        price: line.codArtic === CodArtic
          ? line.price + unities * article[price[Tarclient]] : line.price,
      }));
    } else {
      newOrderLines = [...orderLines, {
        codArtic: CodArtic,
        discount: Descuento || 0,
        price: unities * article[price[Tarclient]],
        originalPrice: article[price[Tarclient]],
        description: DescArtic,
        taxClass: ClaseIvas,
        taxPercent: IvaArtClient === 'C' ? PORCEIVACLIENT : PorceIvaIvas,
        taxSurchargePercent: PorceRECIvas,
        images,
        combos,
        unities,
      }];
    }
    setOrderLines(newOrderLines);
    setModalVisibility(false);
  };

  //   setOrderLines(newOrderLines);
  //   setModalVisibility(false);
  // };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addLine();
    }
  };

  return (
    <>
      <div className="ConsumedArticle">
        <div className="Identifier">
          <Popover content={<img src={images.length > 0 ? `data:image/png;base64, ${images[0].ImagenBase64}` : './images/imagenDefecto.png'} alt="Imagen artículo" />}>
            <img src={images.length > 0 ? `data:image/png;base64, ${images[0].ImagenBase64}` : './images/imagenDefecto.png'} alt="Imagen artículo" />
          </Popover>
          <div className="Information">
            <div>
              <div>{DescArtic}</div>
            </div>
            <div>
              <div>{CodArtic}</div>
            </div>
          </div>
        </div>
        <div>{Cantidad}</div>
        <div>{`${moment(Fecha).format('DD/MM/YYYY')}`}</div>
        <Button
          onBlur={() => setMouseIsOver(false)}
          onFocus={() => setMouseIsOver(true)}
          onMouseOut={() => setMouseIsOver(false)}
          onMouseOver={() => setMouseIsOver(true)}
          style={{ color: fourthColor, backgroundColor: `${mouseIsOver ? fifthColor : thirdColor}`, borderColor: `${mouseIsOver ? fifthColor : thirdColor}` }}
          onClick={() => setModalVisibility(true)}
        >
          Añadir al pedido
        </Button>
      </div>
      <hr />
      <Modal
        width="80%"
        style={{ maxHeight: '80%', overflow: 'auto', paddingBottom: '0px' }}
        visible={modalVisibility}
        onCancel={() => setModalVisibility(false)}
        footer={(
          <ModalFooter
            buttons={[
              { text: 'Cancelar', onClick: () => setModalVisibility(false) },
              { text: 'Añadir', onClick: () => addLine() },
            ]}
          />
        )}
      >
        <div className="ArticleModal">
          <div>
            Introduce las unidades que quieres añadir a la línea del artículo:
          </div>
          <div>
            <span className="CodArtic">{CodArtic}</span>
            {' '}
            <span>{DescArtic}</span>
          </div>
          <div>
            <Input
              autoFocus
              type="number"
              value={unities}
              onFocus={(e) => e.target.select()}
              onChange={(e) => setUnities(e.target.value > 0 ? +e.target.value : 1)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {' '}
            Unidades
          </div>
        </div>
      </Modal>
    </>
  );
};

ConsumedArticle.propTypes = {
  article: PropTypes.shape({
    CodArtic: PropTypes.string.isRequired,
    DescArtic: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    Cantidad: PropTypes.number.isRequired,
    Fecha: PropTypes.string.isRequired,
    Descuento: PropTypes.number,
    ClaseIvas: PropTypes.string.isRequired,
    PorceIvaIvas: PropTypes.number.isRequired,
    PorceRECIvas: PropTypes.number.isRequired,
    PORCEIVACLIENT: PropTypes.number.isRequired,
    Tarclient: PropTypes.string.isRequired,
    IvaArtClient: PropTypes.string.isRequired,
    combos: PropTypes.arrayOf(PropTypes.object).isRequired,
    pvp1artic: PropTypes.number.isRequired,
    pvp2artic: PropTypes.number.isRequired,
    pvp3artic: PropTypes.number.isRequired,
    pvp4artic: PropTypes.number.isRequired,
  }).isRequired,
  orderLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOrderLines: PropTypes.func.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
};

export default ConsumedArticle;
