import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../node_modules/antd/lib/modal';
import Input from '../../../node_modules/antd/lib/input';
import ModalFooter from '../ModalFooter/ModalFooter';
import Popover from '../../../node_modules/antd/lib/popover';
import Utils from '../../Utils';

const { formatDecimals } = Utils;

const Article = (props) => {
  const {
    CodArtic,
    Descuento,
    descartic,
    pvp1artic,
    pvp2artic,
    pvp3artic,
    pvp4artic,
    updateOrderLines,
    taxClass,
    taxPercent,
    taxSurchargePercent,
    taxArticClient,
    taxArticClientPercent,
    clientRate,
    images,
    combos,
    seePrices,
  } = props;

  const price = {
    1: pvp1artic,
    2: pvp2artic,
    3: pvp3artic,
    4: pvp4artic,
  };

  const [modalVisibility, setModalVisibility] = useState(false);
  const [unities, setUnities] = useState(1);
  const article = {
    codArtic: CodArtic,
    discount: Descuento || 0,
    price: price[clientRate],
    description: descartic,
    taxClass,
    taxPercent: taxArticClient === 'C' ? taxArticClientPercent : taxPercent,
    taxSurchargePercent,
    images,
    combos,
  };

  const handleAcceptClick = () => {
    article.originalPrice = article.price;
    const price = unities * (article.price * (1 - (article.discount / 100)));
    article.price = price;
    updateOrderLines(article, unities);
    setModalVisibility(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAcceptClick();
    }
  };

  return (
    <>
      <div className="Article" onClick={() => setModalVisibility(true)}>
        <Popover content={<img src={images.length > 0 ? `data:image/png;base64, ${images[0].ImagenBase64}` : ''} alt="Imagen artículo" />}>
          <img src={images.length > 0 ? `data:image/png;base64, ${images[0].ImagenBase64}` : ''} alt="Imagen artículo" />
        </Popover>
        <div className="Information">
          <div>
            <div>{descartic}</div>
          </div>
          <div>
            <div>{CodArtic}</div>
            <div>{seePrices ? formatDecimals(price[clientRate], 2) : ''}</div>
            {/* <div>{price[clientRate]}</div> */}
          </div>
        </div>
      </div>
      <Modal
        width="80%"
        style={{ maxHeight: '80%', overflow: 'auto', paddingBottom: '0px' }}
        visible={modalVisibility}
        onCancel={() => setModalVisibility(false)}
        footer={(
          <ModalFooter
            buttons={[
              { text: 'Cancelar', onClick: () => setModalVisibility(false) },
              { text: 'Aceptar', onClick: () => handleAcceptClick() },
            ]}
          />
        )}
      >
        <div className="ArticleModal">
          <div>
            Introduce las unidades que quieres añadir a la línea del artículo:
          </div>
          <div>
            <span className="CodArtic">{CodArtic}</span>

            <span>{descartic}</span>
          </div>
          <div>
            <Input
              autoFocus
              type="number"
              value={unities}
              onFocus={(e) => e.target.select()}
              onChange={(e) => setUnities(e.target.value > 0 ? e.target.value : 1)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {' '}
            Unidades
          </div>
        </div>
      </Modal>
    </>
  );
};

Article.propTypes = {
  CodArtic: PropTypes.string.isRequired,
  Descuento: PropTypes.number,
  descartic: PropTypes.string.isRequired,
  pvp1artic: PropTypes.number.isRequired,
  pvp2artic: PropTypes.number.isRequired,
  pvp3artic: PropTypes.number.isRequired,
  pvp4artic: PropTypes.number.isRequired,
  updateOrderLines: PropTypes.func.isRequired,
  taxClass: PropTypes.string.isRequired,
  taxPercent: PropTypes.number.isRequired,
  taxSurchargePercent: PropTypes.number.isRequired,
  taxArticClient: PropTypes.string.isRequired,
  taxArticClientPercent: PropTypes.number.isRequired,
  clientRate: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  combos: PropTypes.arrayOf(PropTypes.object).isRequired,
  seePrices: PropTypes.number.isRequired,
};

Article.defaultProps = {
  Descuento: 0,
};

export default Article;
