import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import MicRecorder from 'mic-recorder-to-mp3';
import * as XLSX from 'xlsx';
import Api from '../../Api';
import Filter from '../../components/Filter/Filter';
import Pager from '../../components/Pager/Pager';
import './search.css';
import Documents from '../../components/Documents/Documents';
import Utils from '../../Utils';
import Modal from '../../../node_modules/antd/lib/modal';
import ModalFooter from '../../components/ModalFooter/ModalFooter';
import Spin from '../../../node_modules/antd/lib/spin';
import Alert from '../../../node_modules/antd/lib/alert';
import notification from '../../../node_modules/antd/lib/notification';
import Tabs from '../../../node_modules/antd/lib/tabs';
import CreateAvt from '../../components/Avts/CreateAvt';
import Order from '../../components/Orders/Order';

const { TabPane } = Tabs;

const {
  getUrl,
  buildHeaders,
  generateParams,
  deleteCookies,
  getBase64,
} = Utils;
const { get, post } = Api;

const Search = (props) => {
  const {
    setIsLogged, profile, thirdColor, fourthColor, orderLines, setOrderLines,
  } = props;
  const history = useHistory();
  const [serfacOptions, setSerfacOptions] = useState([]);
  const [idejeOptions, setIdejeOptions] = useState([]);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [filterValues, setFilterValues] = useState({
    serfac: '', docType: '', ideje: '', client: '', page: 1, elementsPerPage: 10,
  });
  const [totalItems, setTotalItems] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [creatingDocument, setCreatingDocument] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [nextImageId, setNextImageId] = useState(-1);
  const [modalReason, setModalReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [activeTab, setActiveTab] = useState('avt');
  const [avtClient, setAvtClient] = useState('');
  const [orderClient, setOrderClient] = useState('');
  const [orderDirection, setOrderDirection] = useState(0);
  const [modalObservations, setModalObservations] = useState('');
  const [clientInfo, setClientInfo] = useState({});
  const [createTickets, setCreateTickets] = useState(false);
  const [createOrders, setCreateOrders] = useState(false);
  const [seePrices, setSeePrices] = useState(false);
  const [excelSheets, setExcelSheets] = useState([]);
  const uploadRef = useRef('');
  const orderObservationsRef = useRef('');
  const avtReasonRef = useRef('');
  // const [isRecording, setIsRecording] = useState(false);
  // const [blobURL, setBlobURL] = useState('');
  // const [audioBlob, setAudioBlob] = useState('');
  // const [isBlocked, setIsBlocked] = useState(false);
  // const [Mp3Recorder, setMp3Recorder] = useState(new MicRecorder({ bitRate: 128 }));

  const cancelModal = () => {
    if (!loading) {
      setCreatingDocument(false);
      setModalReason(avtReasonRef.current ? avtReasonRef.current.state.value : '');
      // console.log(orderObservationsRef.current);
      setModalObservations(orderObservationsRef.current ? orderObservationsRef.current.state.value : '');
    }
  };

  useEffect(() => {
    if (excelSheets.length !== 0) {
      (
        async () => {
          const body = {
            order: excelSheets[0].data,
          };
          const serverResponse = await post('transformintoorderlines', body, buildHeaders(), getUrl());
          const { articulos, error, mensaje } = serverResponse;
          if (error) {
            notification.error({
              message: 'Error!',
              description: mensaje,
            });
            return;
          }
          const price = {
            1: 'pvp1artic',
            2: 'pvp2artic',
            3: 'pvp3artic',
            4: 'pvp4artic',
          };
          setOrderLines(articulos.map((artic) => ({
            codArtic: artic.CodArtic,
            discount: artic.Descuento || 0,
            price: artic.unities * artic[price[clientInfo.Tarclient]],
            originalPrice: artic[price[clientInfo.Tarclient]],
            description: artic.descartic,
            taxClass: artic.ClaseIvas,
            taxPercent: clientInfo.IvaArtClient === 'C' ? clientInfo.PORCEIVACLIENT : artic.PorceIvaIvas,
            taxSurchargePercent: artic.PorceRECIvas,
            images: artic.images,
            combos: artic.combos,
            unities: artic.unities,
          })));
          notification.success({
            message: 'Success!',
            description: 'Se han cargado las líneas del excel al pedido, puedes comprobar el pedido antes de enviarlo',
          });
        }
      )();
    }
  }, [excelSheets]);

  useEffect(() => {
    if (orderClient) {
      (
        async () => {
          const params = [
            { key: 'client', value: orderClient },
          ];
          const clientInfoResponse = await get(`clientinfo?${generateParams(params)}`,
            buildHeaders());
          const { clientInfo, error, mensaje } = clientInfoResponse;
          if (error) {
            setIsLogged(false);
            deleteCookies();
            history.push('/login');
            return;
          }
          setClientInfo(clientInfo);
        }
      )();
    }
  }, [orderClient]);

  // const start = () => {
  //   if (isBlocked) {
  //     console.log('Permission Denied');
  //   } else {
  //     Mp3Recorder
  //       .start()
  //       .then(() => {
  //         setIsRecording(true);
  //       }).catch((e) => console.error(e));
  //   }
  // };

  // const stop = () => {
  //   Mp3Recorder
  //     .stop()
  //     .getMp3()
  //     .then(([buffer, blob]) => {
  //       console.log(blob);
  //       console.log(buffer);
  //       const newBlobURL = URL.createObjectURL(blob);
  //       setAudioBlob(buffer);
  //       setBlobURL(newBlobURL);
  //       setIsRecording(false);
  //     }).catch((e) => console.log(e));
  // };

  useEffect(() => {
    if (!loading) {
      setLoadingDocuments(true);
      const {
        ideje, docType, serfac, page, elementsPerPage, client,
      } = filterValues;
      if (ideje === '' || docType === '' || serfac === '') return;
      (async () => {
        const params = [
          { key: 'cliente', value: client },
          { key: 'ideje', value: ideje },
          { key: 'serfac', value: serfac },
          { key: 'tipodoc', value: docType },
          { key: 'pagina', value: page - 1 },
          { key: 'numero', value: elementsPerPage },
          { key: 'url', value: getUrl() },
        ];
        const filteredDocuments = await get(`cabeceradoc?${generateParams(params)}`,
          buildHeaders());
        const { error, totales, cabeceras } = filteredDocuments;
        if (error) {
          setIsLogged(false);
          deleteCookies();
          history.push('/login');
          return;
        }
        setTotalItems(totales.NumDocumentos);
        setDocuments(cabeceras);
        setLoadingDocuments(false);
        setAvtClient(client);
        setOrderClient(client);
      })();
    }
  }, [filterValues, loading]);

  useEffect(() => {
    setFilterValues({
      serfac: serfacOptions[0] ? serfacOptions[0].value : '',
      docType: docTypeOptions[0] ? docTypeOptions[0].value : '',
      ideje: idejeOptions[0] ? idejeOptions[0].value : '',
      client: clientOptions[0] ? clientOptions[0].value : '',
      page: 1,
      elementsPerPage: 10,
    });
  }, [serfacOptions, docTypeOptions, idejeOptions, clientOptions]);

  useEffect(() => {
    setLoadingDocuments(true);
    (async () => {
      const filters = await get('filtrosdocumentos',
        buildHeaders());
      const {
        ejercicios: idejes,
        series: serfacs,
        tiposdoc: docTypes,
        clientes: clients,
        error,
        seePrices,
        createTickets,
        createOrders,
      } = filters;
      if (error) {
        setIsLogged(false);
        deleteCookies();
        history.push('/login');
        return;
      }
      setSeePrices(seePrices);
      setCreateOrders(createOrders);
      setCreateTickets(createTickets);
      setIdejeOptions(idejes.map((ideje) => ({
        label: ideje.DesEjercicio, value: ideje.IdEjercicio,
      })));
      setDocTypeOptions(docTypes.map((docType) => ({
        label: docType.descdoc, value: docType.tipodoc,
      })));
      const uniqueSerfacs = new Set();
      serfacs.forEach((serfac) => {
        uniqueSerfacs.add(serfac.serfac);
      });
      setSerfacOptions(Array.from(uniqueSerfacs).map((serfac) => ({
        label: serfac, value: serfac,
      })));
      setClientOptions(clients.map((client) => ({
        label: client.RzsClient, value: client.CodClient,
      })));
    })();
    // eslint-disable-next-line
  }, []);

  const createAvt = async () => {
    setLoading(true);
    const body = {
      reason: avtReasonRef.current.state.value,
      images,
      client: avtClient,
      // blob: audioBlob,
    };
    try {
      const createAvtResult = await post('createavt', body, buildHeaders());
      const { isError, mensaje } = createAvtResult;
      if (isError) {
        notification.error({
          message: 'Error!',
          description: mensaje,
        });
      } else {
        notification.success({
          message: 'Éxito!',
          description:
      'El aviso de taller se ha creado correctamente! Nos haremos cargo en seguida que podamos.',
        });
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    cancelModal();
    setModalReason('');
    setImages([]);
  };

  const createOrder = async () => {
    setLoading(true);
    const body = {
      order: {
        observations: orderObservationsRef.current.state.value,
        lines: orderLines,
        direction: orderDirection,
      },
      client: orderClient,
      // blob: audioBlob,
    };
    try {
      const createOrderResult = await post('createorder', body, buildHeaders());
      const { isError, mensaje } = createOrderResult;
      if (isError) {
        notification.error({
          message: 'Error!',
          description: mensaje,
        });
      } else {
        notification.success({
          message: 'Éxito!',
          description:
      'El pedido se ha realizado correctamente!',
        });
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    cancelModal();
    setOrderLines([]);
    setModalObservations('');
  };

  const createDocument = {
    avt: createAvt,
    order: createOrder,
  };

  const disableAccept = {
    avt: false,
    order: orderLines.length === 0,
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList }) => setImages(fileList);

  const onPaste = (e) => {
    const item = e.clipboardData.items[0];
    if (images.length < 2) {
      if (item.type.indexOf('image') === 0) {
        const blob = item.getAsFile();

        const reader = new FileReader();
        reader.onload = (event) => {
          setImages([...images, {
            uid: nextImageId,
            name: 'paste',
            url: event.target.result,
          }]);
          setNextImageId(nextImageId - 1);
        };

        reader.readAsDataURL(blob);
      }
    }
  };

  const modalButtons = [
    { text: 'Cancelar', onClick: () => cancelModal(), disabled: loading },
  ];

  if (activeTab === 'order') {
    modalButtons.push({ text: 'Subir excel', onClick: () => (uploadRef.current.click()), disabled: loading });
  }

  modalButtons.push({ text: 'Enviar', onClick: () => createDocument[activeTab](), disabled: loading || disableAccept[activeTab] });

  const handleUpload = (file) => {
    if (file !== undefined) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        notification.error({
          message: 'Error!',
          description: 'El formato del archivo no es .xlsx (Excel).',
        });
        return;
      }
      const sheets = [];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        workbook.SheetNames.forEach((sheetName) => {
          const rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          sheets.push({
            data: rowObject,
            sheetName,
          });
        });
        setExcelSheets(sheets);
      };
    }
  };

  return (
    <div className="Search">
      <Filter
        profile={profile}
        setFilterValues={setFilterValues}
        filterValues={filterValues}
        serfacOptions={serfacOptions}
        idejeOptions={idejeOptions}
        docTypeOptions={docTypeOptions}
        clientOptions={clientOptions}
        thirdColor={thirdColor}
        fourthColor={fourthColor}
      />
      {
        loadingDocuments ? (
          <Spin tip="Cargando...">
            <Alert
              message="Cargando"
              description="Estamos obteniendo la información del servidor..."
              type="info"
            />
          </Spin>
        ) : (<Documents documents={documents} setIsLogged={setIsLogged} />)
      }
      <Pager
        totalItems={totalItems}
        setFilterValues={setFilterValues}
        filterValues={filterValues}
      />
      {
        createTickets || createOrders ? (
          <div
            className="CreateDocumentButton"
            onClick={() => setCreatingDocument(true)}
            role="button"
          />
        ) : (<></>)
      }
      <Modal
        width="70%"
        visible={creatingDocument}
        onCancel={() => cancelModal()}
        footer={(
          <ModalFooter
            buttons={modalButtons}
          />
        )}
      >
        {creatingDocument ? (
          <Tabs
            activeKey={activeTab}
            size="small"
            style={{ marginBottom: 32 }}
            onChange={(e) => setActiveTab(e)}
          >
            {
              createTickets ? (
                <TabPane tab="Aviso de taller" key="avt">
                  <CreateAvt
                    loading={loading}
                    clientOptions={clientOptions}
                    setAvtClient={setAvtClient}
                    avtClient={avtClient}
                    profile={profile}
                    onPaste={onPaste}
                    modalReason={modalReason}
                    images={images}
                    handlePreview={handlePreview}
                    handleChange={handleChange}
                    avtReasonRef={avtReasonRef}
                  />
                </TabPane>
              ) : (<></>)
            }
            {
              createOrders ? (
                <TabPane tab="Pedidos" key="order">
                  <Order
                    profile={profile}
                    orderLines={orderLines}
                    setOrderLines={setOrderLines}
                    orderClient={orderClient}
                    setOrderClient={setOrderClient}
                    clientOptions={clientOptions}
                    setModalObservations={setModalObservations}
                    clientInfo={clientInfo}
                    modalObservations={modalObservations}
                    observationsRef={orderObservationsRef}
                    direction={orderDirection}
                    setDirection={setOrderDirection}
                    seePrices={seePrices}
                  />
                </TabPane>
              ) : (<></>)
            }
          </Tabs>
        ) : (<></>)}

      </Modal>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <input
        type="file"
        hidden
        ref={uploadRef}
        onChange={(e) => handleUpload(e.target.files[0])}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </div>
  );
};

Search.propTypes = {
  setIsLogged: PropTypes.func.isRequired,
  profile: PropTypes.number.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  orderLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOrderLines: PropTypes.func.isRequired,
};

export default Search;
