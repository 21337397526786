import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PlusCircleOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Popover from 'antd/lib/popover';
import Modal from 'antd/lib/modal';
import Api from '../../Api';
import Utils from '../../Utils';
import Articles from '../Articles/Articles';
import InputWithLabel from '../InputWithLabel/InputWithLabel';
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel';
import Pager from '../Pager/Pager';
import Imports from '../Imports/Imports';
import OrderLine from './OrderLine';
import './orders.css';
import TextAreaWithLabel from '../TextAreaWithLabel/TextAreaWithLabel';
import notification from '../../../node_modules/antd/lib/notification';
import Spin from '../../../node_modules/antd/lib/spin';
import Tabs from '../../../node_modules/antd/lib/tabs';
import Alert from '../../../node_modules/antd/lib/alert';

const { get } = Api;
const { buildHeaders, getUrl, generateParams } = Utils;
const { TabPane } = Tabs;

const Order = (props) => {
  const {
    orderLines,
    setOrderLines,
    profile,
    clientOptions,
    orderClient,
    setOrderClient,
    clientInfo,
    modalObservations,
    observationsRef,
    direction,
    setDirection,
    seePrices,
  } = props;

  const [articles, setArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const [filterValues, setFilterValues] = useState({
    page: 1, elementsPerPage: 10, filter: '', family: 0, activeTab: 'all',
  });
  const [familyOptions, setFamilyOptions] = useState([]);
  const [directionOptions, setDirectionOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('all');

  const removeLine = (codArtic, description) => {
    setOrderLines(orderLines.filter((artic) => artic.codArtic !== codArtic));
    notification.success({
      message: 'Éxito!',
      description:
      `Se han eliminado las unidades de ${description} con éxito`,
    });
  };

  useEffect(() => {
    (
      async () => {
        const familiesResponse = await get('getfamilies', buildHeaders(), getUrl());
        const { error, mensaje, families } = familiesResponse;
        setFamilyOptions(families.map((family) => ({
          label: family.NomFamili, value: family.CodFamili,
        })));
      }
    )();
  }, []);

  useEffect(() => {
    (
      async () => {
        const params = [
          { key: 'client', value: orderClient },
        ];
        const directionsResponse = await get(`getdirections?${generateParams(params)}`, buildHeaders(), getUrl());
        const { error, mensaje, directions } = directionsResponse;
        setDirectionOptions(directions.map((direction) => ({
          label: `${direction.DirClient}, ${direction.PobClient} (${direction.ProvClient})`, value: direction.CodDirecDE,
        })));
      }
    )();
  }, [clientOptions]);

  useEffect(() => {
    setFilterValues({ ...filterValues, activeTab, page: 1 });
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    const {
      page, elementsPerPage, filter, family,
    } = filterValues;
    (async () => {
      const params = [
        { key: 'filtro', value: filter },
        { key: 'pagina', value: page - 1 },
        { key: 'numero', value: elementsPerPage },
        { key: 'url', value: getUrl() },
        { key: 'client', value: orderClient },
        { key: 'family', value: family },
        { key: 'articleType', value: activeTab },
      ];
      // console.log(orderClient);
      const articlesResponse = await get(`articles?${generateParams(params)}`, buildHeaders(), getUrl());
      const { articles, numberArtics } = articlesResponse;
      // console.log(articlesResponse);

      setArticles(articles);
      setTotalArticles(numberArtics);
      setLoading(false);
    })();
  }, [filterValues, orderClient]);

  const updateOrderLines = (article, unities) => {
    const articExists = orderLines.some((line) => line.codArtic === article.codArtic);
    let newOrderLines = [...orderLines];
    if (articExists) {
      newOrderLines = orderLines.map((line) => ({
        ...line,
        unities: line.codArtic === article.codArtic ? line.unities + unities : line.unities,
        price: line.codArtic === article.codArtic ? line.price + article.price : line.price,
      }));
    } else {
      newOrderLines.push({ ...article, unities });
    }
    setOrderLines(newOrderLines);
    notification.success({
      message: 'Éxito!',
      description:
      `Se han añadido ${unities} unidades de ${article.description}`,
    });
  };

  const updateField = (values, codArtic) => {
    const newOrderLines = orderLines.map((line) => {
      let newLine = { ...line };
      if (line.codArtic === codArtic) {
        newLine = { ...newLine, ...values };
        newLine.price = newLine.unities * (newLine.originalPrice * (1 - (newLine.discount / 100)));
      }
      return newLine;
    });
    setOrderLines(newOrderLines);
  };

  const changeFilter = () => {
    setFilterValues({ ...filterValues, filter: filterInput, page: 1 });
  };

  const onChangeFamily = (value) => {
    setFilterValues({ ...filterValues, family: value, page: 1 });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      changeFilter();
    }
  };

  return (
    <>
      <div className="Order">
        {
          profile === 1 ? (
            <SelectWithLabel
              color="rgba(0, 0, 0, 0.65)"
              label="Cliente"
              id="client"
              options={clientOptions}
              onChange={(e) => setOrderClient(e)}
              defaultValue={orderClient}
            />
          ) : (<></>)
        }
        <div className="OrderLines">
          <div
            className="AddLine"
            role="button"
            tabIndex="0"
            onClick={() => setModalVisibility(true)}
            onKeyPress={() => (null)}
            style={{
              height: `${orderLines.length > 0 ? '100px' : '140px'}`,
              borderBottom: `${orderLines.length > 0 ? '1px black solid' : 'none'}`,
            }}
          >
            <Popover content="Añadir línea">
              <PlusCircleOutlined
                style={{
                  fontSize: '55px', color: '#ff9800', cursor: 'pointer',
                }}
              />
            </Popover>
          </div>
          {
            orderLines.map((line) => (
              <OrderLine
                removeLine={removeLine}
                orderLine={line}
                key={line.codArtic}
                updateField={updateField}
                profile={profile}
                seePrices={seePrices}
              />
            ))
          }
        </div>
        {
        directionOptions.length > 0 ? (
          <SelectWithLabel
            color="rgba(0, 0, 0, 0.65)"
            label="Dirección"
            id="direction"
            options={directionOptions}
            onChange={(e) => setDirection(e)}
            defaultValue={direction}
          />
        ) : (<></>)
          }
        <TextAreaWithLabel
          defaultValue={modalObservations}
          id="CreateOrder"
          placeholder="Añade observaciones al pedido."
          reference={observationsRef}
        />
        <div>
          {
            orderLines.length > 0 && seePrices
              ? (
                <Imports
                  orderLines={orderLines}
                  clientInfo={clientInfo}
                />
              ) : <></>
          }
        </div>
      </div>
      <Modal
        width="80%"
        style={{ maxHeight: '80%', overflow: 'auto', paddingBottom: '0px' }}
        visible={modalVisibility}
        onCancel={() => setModalVisibility(false)}
        footer={(null)}
      >
        {modalVisibility ? (
          <>
            <div className="ModalArticles">
              <Tabs
                activeKey={activeTab}
                size="small"
                onChange={(e) => setActiveTab(e)}
              >
                <TabPane tab="Todos los artículos" key="all" />
                <TabPane tab="Artículos consumidos" key="consumedArticles" />
              </Tabs>
              {
                activeTab === 'consumedArticles'
                  ? (<></>) : (
                    <div className="SelectFamily">
                      <SelectWithLabel
                        color="rgba(0, 0, 0, 0.65)"
                        label="Familias"
                        id="families"
                        options={familyOptions}
                        onChange={onChangeFamily}
                        defaultValue={filterValues.family}
                      />
                    </div>
                  )
              }
              <div className="SearchArticles">
                <InputWithLabel
                  value={filterInput}
                  label="Filtra por descripción o código de artículo"
                  id="ArticleFilter"
                  onChange={setFilterInput}
                  onKeyDown={handleKeyDown}
                />
                <Button onClick={changeFilter}>Buscar</Button>
              </div>
              {
                loading ? (
                  <Spin tip="Cargando...">
                    <Alert
                      message="Cargando"
                      description="Obteniendo artículos del servidor..."
                      type="info"
                    />
                  </Spin>
                )
                  : (
                    <>
                      <Articles
                        familyOptions={familyOptions}
                        updateOrderLines={updateOrderLines}
                        articles={articles}
                        clientInfo={clientInfo}
                        seePrices={seePrices}
                      />
                      <Pager
                        style={{ position: 'sticky', bottom: '0', backgroundColor: 'white' }}
                        totalItems={totalArticles}
                        setFilterValues={setFilterValues}
                        filterValues={filterValues}
                      />
                    </>
                  )
              }
            </div>
          </>
        ) : (<></>)}
      </Modal>
    </>
  );
};

Order.propTypes = {
  orderLines: PropTypes.arrayOf(PropTypes.shape({
    codArtic: PropTypes.string.isRequired,
    combos: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(PropTypes.object),
    originalPrice: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    taxClass: PropTypes.string.isRequired,
    taxPercent: PropTypes.number.isRequired,
    taxSurchargePercent: PropTypes.number.isRequired,
    unities: PropTypes.number.isRequired,
  })).isRequired,
  setOrderLines: PropTypes.func.isRequired,
  profile: PropTypes.number.isRequired,
  clientOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  orderClient: PropTypes.string.isRequired,
  setOrderClient: PropTypes.func.isRequired,
  clientInfo: PropTypes.shape({
    AGENTE: PropTypes.number.isRequired,
    DcoComClient: PropTypes.number.isRequired,
    DcoPpClient: PropTypes.number.isRequired,
    IvaArtClient: PropTypes.string.isRequired,
    PORCEIVACLIENT: PropTypes.number.isRequired,
    PORCERET: PropTypes.number.isRequired,
    RECSN: PropTypes.string.isRequired,
    RETSN: PropTypes.string.isRequired,
    Tarclient: PropTypes.string.isRequired,
    formpagclient: PropTypes.number.isRequired,
  }).isRequired,
  modalObservations: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  observationsRef: PropTypes.any.isRequired,
  direction: PropTypes.number.isRequired,
  setDirection: PropTypes.func.isRequired,
  seePrices: PropTypes.number.isRequired,
};

export default Order;
