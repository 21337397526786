import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../../node_modules/antd/lib/pagination';
import './pager.css';

const Pager = (props) => {
  const {
    totalItems, setFilterValues, filterValues, style,
  } = props;
  const { page } = filterValues;

  const onChangePage = (value) => {
    setFilterValues({ ...filterValues, page: value });
  };

  const onChangeSize = (value) => {
    setFilterValues({ ...filterValues, elementsPerPage: value, page: 1 });
  };

  return (
    <div className="Pager" style={style}>
      <Pagination
        showSizeChanger
        hideOnSinglePage={totalItems === 0}
        total={totalItems}
        current={page}
        onChange={(e) => onChangePage(e)}
        showTotal={(total) => `Total ${total}`}
        onShowSizeChange={(e, current) => onChangeSize(current)}
      />
    </div>
  );
};

Pager.propTypes = {
  totalItems: PropTypes.number.isRequired,
  filterValues: PropTypes.shape({
    serfac: PropTypes.string,
    docType: PropTypes.string,
    ideje: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filtro: PropTypes.string,
    page: PropTypes.number.isRequired,
    elementsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  setFilterValues: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.string),
};

Pager.defaultProps = {
  style: {},
};

export default Pager;
