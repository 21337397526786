import React, { useState } from 'react';
import './profile.css';
import PropTypes from 'prop-types';
import InputWithLabel from '../../components/InputWithLabel/InputWithLabel';
import Utils from '../../Utils';
import Api from '../../Api';
import Button from '../../../node_modules/antd/lib/button';
import notification from '../../../node_modules/antd/lib/notification';

const { getCookies, saveCookies } = Utils;
const { post } = Api;

const Profile = (props) => {
  const {
    hasPassword, setHasPassword, thirdColor, fourthColor, fifthColor,
  } = props;
  const cookies = getCookies();
  const { user } = cookies;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setrepeatNewPassword] = useState('');
  const [mouseOver, setMouseOver] = useState(false);

  async function updatePassword() {
    if (newPassword !== repeatNewPassword) {
      setError(true);
      setMessage('Las contraseñas nuevas no coinciden');
      return;
    }
    setLoading(true);

    const response = await post('updatepassword', {
      user,
      oldPassword,
      newPassword,
    });
    const {
      mensaje, error: isError,
    } = response;
    setLoading(false);
    setMessage(mensaje);
    setError(isError);
    if (!isError) {
      notification.success({
        message: 'Success!',
        description:
      'Tu contraseña ha sido cambiada con éxito!',
      });
      setHasPassword(true);
      cookies.hasPassword = true;
      saveCookies(cookies);
    }
  }

  return (
    <div className="Profile">
      <h2>Perfil</h2>
      {
        error ? <span className="Error">{message}</span> : <></>
      }
      <div className="Box">
        <InputWithLabel
          label="Usuario"
          readOnly
          value={user}
          disabled
          id="usuario"
          onChange={() => {}}
        />
        <InputWithLabel
          label="Antigua contraseña"
          value={oldPassword}
          type="password"
          id="Antigua contraseña"
          disabled={!hasPassword}
          onChange={setOldPassword}
        />
        <InputWithLabel
          label="Nueva contraseña"
          value={newPassword}
          type="password"
          id="Nueva contraseña"
          onChange={setNewPassword}
        />
        <InputWithLabel
          label="Repite nueva contraseña"
          value={repeatNewPassword}
          type="password"
          id="Repite nueva contraseña"
          onChange={setrepeatNewPassword}
        />
        <Button
          style={{
            backgroundColor: mouseOver ? fifthColor : thirdColor,
            borderColor: mouseOver ? fifthColor : thirdColor,
            fontWeight: 'bold',
            color: fourthColor,
          }}
          onBlur={() => setMouseOver(false)}
          onFocus={() => setMouseOver(true)}
          onMouseOut={() => setMouseOver(false)}
          onMouseOver={() => setMouseOver(true)}
          type="default"
          onClick={updatePassword}
          loading={loading}
        >
          Cambiar contraseña
        </Button>
      </div>
    </div>
  );
};

Profile.propTypes = {
  hasPassword: PropTypes.bool.isRequired,
  setHasPassword: PropTypes.func.isRequired,
  thirdColor: PropTypes.string.isRequired,
  fourthColor: PropTypes.string.isRequired,
  fifthColor: PropTypes.string.isRequired,
};

export default Profile;
